import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { combineLatest, map, Observable } from 'rxjs'
import { ScopingSelectors } from '../../store/selectors/scoping.selector';
import { DataMappingService } from '../../service/data-mapping.service';
import { ScopeActions } from '../../store/actions/scoping.actions';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { Pagination } from '@core/model/definitions/pagination.interface';
import { Preference } from '@app/core/model/user-preferences.interface';
import { BootstrapActions } from '@app/core/store';
import { User } from '@core/model/user.model';
import { ScopeFilter, ScopeFilterDefaults } from '@core/model/scope-filter.model';
import { ScopeHighlights } from '@app/features/scoping/models/scope-highlights.model';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router'
import { LanguageService } from '@core/service/language.service';
import { untilDestroyed } from '@shared/utils/utils';
import { MatDialog } from '@angular/material/dialog';
import { CompanyType } from '@core/model/enums/company-type.enum';
import { isEqual } from 'lodash';
import { ScopeUiModalComponent } from "@shared/components/ui-components/scope-ui-modal/scope-ui-modal.component";
import { ModalConfig } from "@core/model/modal-config.model";
import { MappedEntity } from "@app/features/scoping/models/mapped-entity.model";
import { ScopeVersion, scopeStatuses } from "@core/model/scope-version";
import { FolderVersion, folderStatuses } from "@core/model/folder-version.model";
import { Page } from "@app/features/scoping/models/page.model";
import { Privilege } from "@core/model/enums/privilege.enum";
import { MenuOptions } from '@app/core/model/definitions/menu-options.interface';
import { ScopeUiFilterModalComponent } from "@shared/components/ui-components/scope-ui-filter-modal/scope-ui-filter-modal.component";
import {
  ComponentsFilterValue,
  DateFilterValue,
  FilterOption,
  FilterOptions,
  GenericFilterValue,
  RangeFilterValue,
  TypedFilterValue,
} from '@core/model/filter-option.interface'
import { instanceToInstance, plainToInstance } from 'class-transformer'
import { RegisteredSubscription, WebsocketService } from '@shared/services/websocket.service';
import { EventType } from '@app/core/model/enums/event-type.enum';
import { ScopeOverviewSelectors } from '@app/features/scope-overview/store/selectors/scope-overview.selector';
import {
  SnackbarEventType,
  SNACKBAR_LENGTH_LONG,
  SnackbarService,
} from '@shared/utils/snackbar.service'
import { AuthService } from '@core/service/auth.service'
import { SecondParty } from '@core/model/second-party.model';
import { ScopeTabService } from '@app/features/scope-overview/service/scope-tab.service';

@Component({
  selector: 'myscopes',
  templateUrl: './myscopes.component.html',
  styleUrls: ['./myscopes.component.scss'],
})
export class MyScopesComponent implements OnInit, AfterViewInit, OnDestroy {
  private readonly destroy$

  cardActiveStates: { [key: string]: boolean }

  activeCardType: string | null = null
  loggedInUser!: User
  filters!: ScopeFilter
  showMainFilters: boolean = true
  isTopFiltersSelected: boolean = false
  currentTab: string = this.lang.get('scope.p')
  showArchive: boolean = false

  rawScopes$: Observable<Page<ScopeVersion>>
  rawArchivedScopes$: Observable<Page<ScopeVersion>>
  rawFolders$: Observable<Page<FolderVersion>>
  rawArchivedFolders$: Observable<Page<FolderVersion>>
  allFolders$: Observable<FolderVersion[]>
  columns$: Observable<Preference[]>;
  folderColumns$: Observable<Preference[]>;
  templateColumns$: Observable<Preference[]>;

  deliverableColumns: string[]

  mappedScopesDataSource$!: Observable<MappedEntity<ScopeVersion>[]>
  mappedArchivedScopesDataSource$!: Observable<MappedEntity<ScopeVersion>[]>
  mappedFoldersDataSource$!: Observable<MappedEntity<FolderVersion>[]>
  mappedArchivedFoldersDataSource$!: Observable<MappedEntity<FolderVersion>[]>

  scopeTableConfig$!: Observable<Pagination>
  archivedScopeTableConfig$!: Observable<Pagination>
  scopeFilters$!: Observable<ScopeFilter>
  highlights$: Observable<ScopeHighlights>
  highlights?: ScopeHighlights
  searchedDeliverables$?: Observable<any[]>
  searchedComponents$?: Observable<any[]>
  filtersToShow: any[] = []
  secondParties: SecondParty[] = []

  errorMessage$: Observable<string>
  isLoading$: Observable<boolean>
  isLoaded$: Observable<boolean>
  isScopeLoading$: Observable<boolean>
  isLoadingArchive$: Observable<boolean>
  isLoadedArchive$: Observable<boolean>
  isLoadingColumns$: Observable<boolean>

  menuOptions: MenuOptions[] = []
  registeredSubs: RegisteredSubscription[] = []

  scopesFilterConfig: { [key: string]: string }

  scopeMenuOptions: MenuOptions[]
  folderMenuOptions: MenuOptions[]
  folderScopeMenuOptions: MenuOptions[]

  constructor(
    private store: Store,
    private mappingService: DataMappingService,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private lang: LanguageService,
    public dialog: MatDialog,
    private webSocketService: WebsocketService,
    private snackbarService: SnackbarService,
    public route: ActivatedRoute,
    private authService: AuthService,
    private scopeTabService: ScopeTabService
  ) {
    this.destroy$ = untilDestroyed()
    this.loggedInUser = this.authService.loggedInUser
    this.cardActiveStates = {
      toReviewAndSign: false,
      created: false,
      approved: false,
    }
    this.rawScopes$ = this.store.select(ScopingSelectors.selectMyScopes)
    this.rawArchivedScopes$ = this.store.select(ScopingSelectors.selectArchivedScopes)
    this.rawFolders$ = this.store.select(ScopingSelectors.selectFolders)
    this.rawArchivedFolders$ = this.store.select(ScopingSelectors.selectArchivedFolders)
    this.allFolders$ = this.store.select(ScopingSelectors.selectAllFolders)
    this.columns$ = this.store.select(ScopingSelectors.selectDisplayedScopeColumns)
    this.folderColumns$ = this.store.select(ScopingSelectors.selectDisplayedFolderColumns)
    this.templateColumns$ = this.store.select(ScopingSelectors.selectDisplayedTemplateColumns)
    this.scopeTableConfig$ = this.store.select(ScopingSelectors.selectPagination)
    this.archivedScopeTableConfig$ = this.store.select(ScopingSelectors.selectPaginationArchived)
    this.scopeFilters$ = this.store.select(ScopingSelectors.selectFilters)
    this.highlights$ = this.store.select(ScopingSelectors.selectHighlights)
    this.searchedDeliverables$ = this.store.select(ScopingSelectors.selectSearchedDeliverables)
    this.searchedComponents$ = this.store.select(ScopingSelectors.selectSearchedComponents)
    this.errorMessage$ = this.store.select(ScopingSelectors.selectErrorMessage)
    this.isLoading$ = this.store.select(ScopingSelectors.selectIsLoading)
    this.isLoaded$ = this.store.select(ScopingSelectors.selectIsLoaded)
    this.isLoadingArchive$ = this.store.select(ScopingSelectors.selectIsLoadingArchive)
    this.isLoadedArchive$ = this.store.select(ScopingSelectors.selectIsLoadedArchive)
    this.isLoadingColumns$ = this.store.select(ScopingSelectors.selectIsLoadingColumns)
    this.isScopeLoading$ = this.store.select(ScopeOverviewSelectors.selectCurrentScopeLoading)


    this.scopesFilterConfig = {
      inFolder: `In ${lang.get('sow')}`,
      companyAccounts: 'Account',
      secondParties: lang.get('second_party'),
      brands: lang.get('brand'),
      date: 'Date',
      creators: 'Creator',
      scopeStatuses: 'Status',
      folderStatuses: 'Status',
      budget: 'Budget',
      ratecards: 'Rate card',
      currencies: 'Currency',
      createdBy: 'Created By',
      deliverables: lang.get('deliverable'),
      components: lang.get('component'),
    }

    this.deliverableColumns = ['name', 'updatedTs', 'totalCostPrice']
  }

  ngOnInit(): void {
    this.mapScopeConfigToDataSource()
    this.mapScopeArchiveConfigToDataSource()
    this.mapFolderConfigToDataSource()
    this.mapFolderArchiveConfigToDataSource()
    this.store.dispatch(BootstrapActions.loadUserPreferences())
    this.fetchHighlights()
    this.getScopeFilters()
    this.handleErrors()
    this.setMenuOptions()
    let that = this
    this.registeredSubs.push(this.webSocketService.registerScopesEventReceiver(function(event: any) {
      switch (event.type){
        case EventType.SCOPE_ADDED: {
          that.fetchHighlights()
        }
      }
    }))
  }

  ngAfterViewInit(): void {
      this.cdr.detectChanges()
  }

  ngOnDestroy() {
    this.registeredSubs.forEach(s => this.webSocketService.unregisterReceiver(s))
    this.registeredSubs = []
  }

  handleErrors() {
    this.errorMessage$.pipe(this.destroy$()).subscribe((message) => {
      if (message) {
        console.error(message)
        this.snackbarService.showSnackbar(`Error: ${message}`, SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
      }
    })
  }

  getScopeFilters() {
    this.scopeFilters$.pipe(this.destroy$()).subscribe((filters: ScopeFilter) => {
      this.filters = filters
      this.isTopFiltersSelected =
        filters.toReviewAndSignSelected || filters.approvedSelected || filters.newScopeCreatedSelected
    })
  }

  mapScopeConfigToDataSource() {
    this.mappedScopesDataSource$ = combineLatest([this.rawScopes$, this.columns$]).pipe(
      map(([scopes, userPreferences]) => {
        return this.mappingService.transformScopeArray(scopes.content, userPreferences)
      })
    )
  }

  mapScopeArchiveConfigToDataSource() {
    this.mappedArchivedScopesDataSource$ = combineLatest([this.rawArchivedScopes$, this.columns$]).pipe(
      map(([scopes, userPreferences]) => {
        return this.mappingService.transformScopeArray(scopes.content, userPreferences)
      })
    )
  }

  mapFolderConfigToDataSource() {
    this.mappedFoldersDataSource$ = combineLatest([this.rawFolders$, this.folderColumns$]).pipe(
      map(([folders, userPreferences]) => {
        return this.mappingService.transformFolderArray(folders.content, userPreferences)
      })
    )
  }

  mapFolderArchiveConfigToDataSource() {
    this.mappedArchivedFoldersDataSource$ = combineLatest([this.rawArchivedFolders$, this.folderColumns$]).pipe(
      map(([folders, userPreferences]) => {
        return this.mappingService.transformFolderArray(folders.content, userPreferences)
      })
    )
  }

  selectTab(tabTitle: string, resetPage: boolean = false) {
    this.currentTab = tabTitle
    let props: { filters: ScopeFilter, page?: number, pageArchived?: number } = { filters: { ...this.filters } }
    if (resetPage) props = { ...props, page: 0, pageArchived: 0 }

    if (tabTitle === this.lang.get('scope.p')) {
      this.router.navigate([], { queryParams: null })
      props.filters.isTemplate = false
      this.store.dispatch(ScopeActions.filterScopes(props))
    } else if (tabTitle === 'Templates') {
      this.router.navigate([], { queryParams: { tab: 'templates' } })
      props.filters.isTemplate = true
      this.store.dispatch(ScopeActions.filterScopes(props))
    } else if (tabTitle === this.lang.get('scope_of_work.p')) {
      this.router.navigate([], { queryParams: { tab: 'folders' } })
      props.filters.isTemplate = false
      this.store.dispatch(ScopeActions.filterFolders(props))
    }
  }

  onChangePage(pageEvent: PageEvent) {
    if (this.currentTab === this.lang.get('scope_of_work.p')) {
      const { pageIndex, pageSize } = pageEvent
      this.store.dispatch(ScopeActions.filterFoldersPageInfo({ pageInfo: { page: pageIndex, pageSize } }))
    } else {
      const { pageIndex, pageSize } = pageEvent
      this.store.dispatch(ScopeActions.filterScopesPageInfo({ pageInfo: { page: pageIndex, pageSize } }))
    }
  }

  onChangePageArchived(pageEvent: PageEvent) {
    if (this.currentTab === this.lang.get('scope_of_work.p')) {
      const { pageIndex, pageSize } = pageEvent
      this.store.dispatch(ScopeActions.filterArchivedFoldersPageInfo({ pageInfo: { page: pageIndex, pageSize } }))
    } else {
      const { pageIndex, pageSize } = pageEvent
      this.store.dispatch(ScopeActions.filterArchivedScopesPageInfo({ pageInfo: { page: pageIndex, pageSize } }))
    }
  }

  onSort(sort: Sort) {
    if (this.currentTab === this.lang.get('scope_of_work.p')) {
      this.store.dispatch(ScopeActions.filterFoldersSort({ sort }))
    } else {
      this.store.dispatch(ScopeActions.filterScopesSort({ sort }))
    }
  }

  onSortArchived(sort: Sort) {
    if (this.currentTab === this.lang.get('scope_of_work.p')) {
      this.store.dispatch(ScopeActions.filterArchivedFoldersSort({ sort }))
    } else {
      this.store.dispatch(ScopeActions.filterArchivedScopesSort({ sort }))
    }
  }

  toggleMainFilters() {
    this.showMainFilters = !this.showMainFilters
    localStorage.setItem('topFiltersShow', String(this.showMainFilters))
    if (!this.showMainFilters) {
      if (this.isTopFiltersSelected) {
        this.unSelectTopFilter()
      }
    }
  }

  onToggleScopePreference(preference: Preference) {
    this.store.dispatch(BootstrapActions.updateScopeColumnPreferences({ updatedPrefs: preference }))
  }

  fetchHighlights() {
    this.store.dispatch(ScopeActions.getHighlights())

    this.highlights$.pipe(this.destroy$()).subscribe((highlights: ScopeHighlights) => {
      this.highlights = highlights
    })
  }

  resetAndGetScopes(params: any) {
    if (this.currentTab === this.lang.get('scope_of_work.p')) {
      this.store.dispatch(ScopeActions.filterFolders({ filters: params, page: 0, pageArchived: 0 }))
    } else {
      this.store.dispatch(ScopeActions.filterScopes({ filters: params, page: 0, pageArchived: 0 }))
    }
  }

  onCancelPressed() {
    if (!this.filters.name) {
      return;
    }

    this.filters = {
      ...this.filters,
      name: undefined,
      newCreatedSince: undefined,
      approvedSince: undefined,
      toReviewAndSignSince: undefined,
      toReviewAndSignSelected: false,
      approvedSelected: false,
      newScopeCreatedSelected: false,
    };

    this.resetAndGetScopes(this.filters);
  }

  unSelectTopFilter() {
    let params = {
      newCreatedSince: undefined,
      approvedSince: undefined,
      toReviewAndSignSince: undefined,
      toReviewAndSignSelected: false,
      approvedSelected: false,
      newScopeCreatedSelected: false,
    }
    this.resetAndGetScopes(params)
  }

  handleCardClick(cardType: string) {
    if (this.activeCardType === cardType) {
      this.activeCardType = null
    } else {
      if (this.activeCardType !== null) {
        this.cardActiveStates[this.activeCardType] = false
      }
      this.activeCardType = cardType
    }

    this.cardActiveStates[cardType] = !this.cardActiveStates[cardType]

    switch (cardType) {
      case 'toReviewAndSign':
        this.selectToReviewAndSignFilter()
        break
      case 'created':
        this.selectNewScopeCreatedFilter()
        break
      case 'approved':
        this.selectApprovedFilter()
        break
    }
  }

  selectToReviewAndSignFilter() {
    if (!this.filters.toReviewAndSignSelected) {
      let params = {
        ...ScopeFilterDefaults,
        isTemplate: this.filters.isTemplate,
        toReviewAndSignSelected: true,
        toReviewAndSignSince: (this.loggedInUser.lastLoginDate || new Date(1)).toISOString(),
      }
      this.resetAndGetScopes(params)
    } else {
      this.unSelectTopFilter()
    }
  }

  selectApprovedFilter() {
    if (!this.filters.approvedSelected) {
      let params = {
        ...ScopeFilterDefaults,
        isTemplate: this.filters.isTemplate,
        approvedSelected: true,
        approvedSince: (this.loggedInUser.lastLoginDate || new Date(1)).toISOString(),
      }
      this.resetAndGetScopes(params)
    } else {
      this.unSelectTopFilter()
    }
  }

  selectNewScopeCreatedFilter() {
    if (!this.filters.newScopeCreatedSelected) {
      let params = {
        ...ScopeFilterDefaults,
        isTemplate: this.filters.isTemplate,
        newScopeCreatedSelected: true,
        newCreatedSince: (this.loggedInUser.lastLoginDate || new Date(1)).toISOString(),
      }
      this.resetAndGetScopes(params)
    } else {
      this.unSelectTopFilter()
    }
  }

  onSearchKeyPressed(value: string) {
    this.filtersToShow.push({ type: 'search', text: value, el: null })
    this.filters = {
      ...this.filters,
      name: value,
      newCreatedSince: undefined,
      approvedSince: undefined,
      toReviewAndSignSince: undefined,
      toReviewAndSignSelected: false,
      approvedSelected: false,
      newScopeCreatedSelected: false,
    }

    this.resetAndGetScopes(this.filters)
  }

  mapToArray(object: any, valueAsKey: boolean = false) {
    return Object.entries(object).reduce((acc: any[], [key, value]) => {
      acc.push(plainToInstance(GenericFilterValue, { id: valueAsKey ? value : key, name: value }))
      return acc
    }, [])
  }

  resetFilters() {
    this.filters = ScopeFilterDefaults
    this.resetAndGetScopes(this.filters)
  }

  onSelectFilters() {
    const filterModal = this.dialog.open(ScopeUiFilterModalComponent, {
      width: '328px',
      position: { right: '40px' },
      data: {
        options: {
          inFolder: new FilterOption<string>(
            this.scopesFilterConfig['inFolder'],
            'radio',
            undefined,
            [
              { name: 'All', value: 'All' },
              { name: 'Yes', value: 'Yes' },
              { name: 'No', value: 'No' },
            ],
            undefined,
            () => this.currentTab !== this.lang.get('scope_of_work.p'),
            this.filters.inFolder
          ),
          companyAccounts: new FilterOption<GenericFilterValue[]>(
            this.scopesFilterConfig['companyAccounts'],
            'genericSearch',
            'Select accounts',
            this.mapToArray(this.highlights?.companyAccounts),
            undefined,
            () => Object.entries(this.highlights?.companyAccounts || {}).length > 1,
            instanceToInstance(this.filters.companyAccounts)
          ),
          secondParties: new FilterOption<GenericFilterValue[]>(
            this.scopesFilterConfig['secondParties'],
            'genericSearch',
            'Select clients',
            this.mapToArray(this.highlights?.secondParties),
            undefined,
            () => Object.entries(this.highlights?.secondParties || {}).length,
            instanceToInstance(this.filters.secondParties)
          ),
          brands: new FilterOption<GenericFilterValue[]>(
            this.scopesFilterConfig['brands'],
            'genericSearch',
            'Select brands',
            this.mapToArray(this.highlights?.brands),
            undefined,
            () => Object.entries(this.highlights?.brands || {}).length,
            instanceToInstance(this.filters.brands)
          ),
          date: new FilterOption<DateFilterValue>(
            this.scopesFilterConfig['date'],
            'date',
            undefined,
            [
              { name: 'Created', value: 'CREATED' },
              { name: 'Edited', value: 'EDITED' },
              { name: 'Reviewed', value: 'REVIEWED' },
              { name: 'Approved', value: 'APPROVED' },
            ],
            undefined,
            undefined,
            instanceToInstance(this.filters.date)
          ),
          creators: new FilterOption<GenericFilterValue[]>(
            this.scopesFilterConfig['creators'],
            'genericSearch',
            'Select creators',
            this.mapToArray(this.highlights?.creators),
            undefined,
            () => Object.entries(this.highlights?.creators || {}).length,
            instanceToInstance(this.filters.creators)
          ),
          scopeStatuses: new FilterOption<any[]>(
            this.scopesFilterConfig['scopeStatuses'],
            'genericSearch',
            'Select statuses',
            this.mapToArray(scopeStatuses),
            undefined,
            () => this.currentTab !== this.lang.get('scope_of_work.p'),
            instanceToInstance(this.filters.scopeStatuses)
          ),
          folderStatuses: new FilterOption<any[]>(
            this.scopesFilterConfig['folderStatuses'],
            'genericSearch',
            'Select statuses',
            this.mapToArray(folderStatuses),
            undefined,
            () => this.currentTab === this.lang.get('scope_of_work.p'),
            instanceToInstance(this.filters.folderStatuses)
          ),
          budget: new FilterOption<RangeFilterValue>(
            this.scopesFilterConfig['budget'],
            'range',
            undefined,
            undefined,
            undefined,
            undefined,
            instanceToInstance(this.filters.budget),
            undefined,
            0
          ),
          ratecards: new FilterOption<GenericFilterValue[]>(
            this.scopesFilterConfig['ratecards'],
            'genericSearch',
            'Select ratecards',
            this.mapToArray(this.highlights?.ratecards),
            undefined,
            () => this.highlights?.ratecards && Object.entries(this.highlights?.ratecards).length,
            instanceToInstance(this.filters.ratecards)
          ),
          currencies: new FilterOption<string[]>(
            this.scopesFilterConfig['currencies'],
            'genericSearch',
            'Select currencies',
            this.mapToArray(this.highlights?.currencies, true),
            undefined,
            () => this.highlights?.currencies && Object.entries(this.highlights?.currencies).length,
            instanceToInstance(this.filters.currencies)
          ),
          createdBy: new FilterOption<GenericFilterValue[]>(
            this.scopesFilterConfig['createdBy'],
            'genericSearch',
            'Select created by account',
            this.mapToArray(this.highlights?.createdByAccounts),
            undefined,
            () => Object.entries(this.highlights?.createdByAccounts || {}).length,
            instanceToInstance(this.filters.createdBy)
          ),
          deliverables: new FilterOption<TypedFilterValue[]>(
            this.scopesFilterConfig['deliverables'],
            'genericSearch',
            undefined,
            undefined,
            this.searchedDeliverables$,
            undefined,
            instanceToInstance(this.filters.deliverables),
            (event: { searchString: string }) => {
              this.store.dispatch(ScopeActions.searchDeliverables({ searchText: event.searchString }))
            },
            undefined,
            undefined,
            true
          ),
          components: new FilterOption<ComponentsFilterValue>(
            this.scopesFilterConfig['components'],
            'componentSearch',
            undefined,
            undefined,
            this.searchedComponents$,
            undefined,
            instanceToInstance(this.filters.components),
            (event: { searchString: string }) => {
              this.store.dispatch(ScopeActions.searchComponents({ searchText: event.searchString }))
            },
            undefined,
            undefined,
            true
          ),
        },
        filterDefaults: ScopeFilterDefaults,
        reset: () => {
          this.resetFilters()
        },
        seeResults: (filters: FilterOptions) => {
          filterModal.close()
          this.filters = {
            ...this.filters,
            inFolder: filters['inFolder'].value,
            secondParties: filters['secondParties'].value,
            companyAccounts: filters['companyAccounts'].value,
            brands: filters['brands'].value,
            date: filters['date'].value,
            creators: filters['creators'].value,
            createdBy: filters['createdBy'].value,
            scopeStatuses: filters['scopeStatuses'].value,
            folderStatuses: filters['folderStatuses'].value,
            budget: filters['budget'].value,
            ratecards: filters['ratecards'].value,
            currencies: filters['currencies'].value,
            deliverables: filters['deliverables'].value,
            components: filters['components'].value,
          }
          this.resetAndGetScopes(this.filters)
        },
      },
    })
  }

  scopeByRoleEnabled() {
    return this.loggedInUser.company.hasApplicationSetting('SCOPE_BY_ROLE__ENABLE')
  }

  deliverableActualsEnabled() {
    return this.loggedInUser.company.hasApplicationSetting('SCOPE__TRAFFICKING__DELIVERABLE_ACTUALS')
  }

  urlCreateScope() {
    switch (this.loggedInUser.company.companyType) {
      case CompanyType.LEGAL:
        return '/create-matter'
      default:
        return '/create-scope'
    }
  }

  setMenuOptions() {
    if (
      this.loggedInUser.hasPrivilege(Privilege.SOW__CREATE) &&
      this.loggedInUser.company.hasApplicationSetting('SOW__GLOBAL')
    ) {
      this.menuOptions.push({
        name: () => this.lang.get('sow'),
        icon: () => 'lists',
        callback: () => {
          this.router.navigateByUrl('/create-folder')
        },
      })
    }
    if (this.loggedInUser.hasPrivilege(Privilege.SCOPE__CREATE)) {
      this.menuOptions.push({
        name: () => this.lang.get('scope') + (this.scopeByRoleEnabled() ? ' by Work' : ''),
        icon: () => 'equalizer',
        callback: () => {
          this.router.navigateByUrl(this.urlCreateScope())
        },
      })
    }
    if (
      this.loggedInUser.hasPrivilege(Privilege.SCOPE__CREATE) &&
      this.loggedInUser.company.hasApplicationSetting('SCOPE_BY_ROLE__ENABLE')
    ) {
      this.menuOptions.push({
        name: () => this.lang.get('scope') + ' by Role',
        icon: () => 'table_chart',
        callback: () => {
          this.router.navigateByUrl('/create-scope-by-role')
        },
      })
    }
    if (this.loggedInUser.hasPrivilege(Privilege.SCOPE_TEMPLATE__CREATE)) {
      this.menuOptions.push({
        name: () => 'Template',
        icon: () => 'copy_all',
        callback: () => {
          this.router.navigateByUrl('/create-template')
        },
      })
    }

    this.scopeMenuOptions = [{
      isHidden: (element: any) => !element.entity.isTemplate(),
      callback: (element) => this.useTemplate(element.entity),
      icon: () => 'assignment',
      name: () => 'Use template'
    }, {
      hasPrivilegeEntity: (element: any) => !element.entity,
      hasPrivilege: () => 'SCOPE__ARCHIVE',
      callback: (element) => this.onShowArchiveDialog(element.entity),
      icon: (element) => !element.entity.isArchived() ? 'archive' : 'unarchive',
      name: (element) => (!element.entity.isArchived() ? 'Archive ' : 'Unarchive ') + (element.entity.isTemplate() ? 'Template' : this.lang.get('scope'))
    }, {
      isHidden: (element: any) => !element.entity.isStateApproved() && !(element.entity.status == 'TRAFFICKED'),
      hasPrivilegeEntity: (element: any) => element.entity,
      hasPrivilege: () => 'SCOPE__CLOSE',
      callback: (element) => this.onShowCloseScopeDialog(element.entity),
      icon: () => 'indeterminate_check_box',
      name: (element) => 'Close ' + (element.entity.isTemplate() ? 'Template' : this.lang.get('scope'))
    }, {
      isHidden: (element: any) => this.isScopeByRole(element),
      hasPrivilege: () => 'SCOPE__CREATE',
      callback: (element) => this.onDuplicateScope(element.entity),
      icon: () => 'content_copy',
      name: (element) =>  'Duplicate ' + (element.entity.isTemplate() ? 'Template' : this.lang.get('scope'))
    }, {
      isHidden: (element: any) => element.entity.status !== 'DRAFT',
      hasPrivilege: (element) => element.entity.isTemplate() ? 'SCOPE_TEMPLATE__DELETE' : 'SCOPE__DELETE',
      hasPrivilegeEntity: (element: any) => element.entity,
      callback: (element) => this.onShowDeleteDialog(element.entity),
      icon: () => 'delete',
      name: (element) =>  'Delete ' + (element.entity.isTemplate() ? 'Template' : this.lang.get('scope'))
    }, {
      isHidden: (element: any) => element.entity.status !== 'DRAFT' ||
        element.entity.scopeOfWorkVersion?.status !== 'DRAFT' || element.entity.isTemplate() || !element.entity.isOfSow(),
      hasPrivilege: () => 'SCOPE__EDIT',
      callback: (element) => this.onMoveSingleScopeOutOfFolder(element.entity),
      icon: () => 'drive_file_move',
      name: () => `Move ${this.lang.get('scope')} out from ${this.lang.get('sow|l')}`,
    }, {
      isHidden: (element: any) => element.entity.status !== 'DRAFT' || element.entity.isTemplate() || element.entity.isOfSow(),
      hasPrivilege: () => 'SCOPE__EDIT',
      callback: (element) => this.onMoveSingleScopeToFolder(element.entity),
      icon: () => 'drive_file_move_rtl',
      name: () => `Move ${this.lang.get('scope')} to ${this.lang.get('sow|l')}`
    }]

    this.folderMenuOptions = [{
      hasPrivilege: () => 'SOW__ARCHIVE',
      hasPrivilegeEntity: (element: any) => element.entity,
      callback: (element) => this.onShowArchiveSowDialog(element.entity),
      icon: (element) => !element.entity.isArchived() ? 'archive' : 'unarchive',
      name: (element) => `${!element.entity.isArchived() ? 'Archive' : 'Unarchive'} ${this.lang.get('sow|l')}`
    }, {
      hasPrivilege: () => 'SOW__CREATE',
      callback: (element) => this.onDuplicateSow(element.entity),
      icon: () => 'content_copy',
      name: () => `Duplicate ${this.lang.get('sow|l')}`
    }, {
      hasPrivilege: () => 'SOW__DELETE',
      hasPrivilegeEntity: (element: any) => element.entity,
      callback: (element) => this.onShowDeleteDialog(element.entity),
      icon: () => 'delete',
      name: () => `Delete ${this.lang.get('sow|l')}`
    }]

    this.folderScopeMenuOptions = [{
      callback: (element) => this.onSelect(element),
      icon: () => 'edit',
      name: () => `Edit ${this.lang.get('scope|l')}`
    }]
  }

  useTemplate(element: ScopeVersion) {
    this.router.navigateByUrl('/use-template/' + element.identity.id);
  }

  isScopeByRole(element: MappedEntity<any>) {
    return element.entity && element.entity.identity.identificationType === 'SCOPE_BY_ROLE';
  }

  onExpand(element: MappedEntity<any>) {
    if (!element.children$) {
      if (element.entity instanceof ScopeVersion) {
        this.store.dispatch(ScopeActions.getDeliverableSummaries({ scopeId: element.entity.identity.id }))
        element.children$ = this.store.pipe(
          select(ScopingSelectors.selectDeliverablesByScope(element.entity.identity.id))
        )
      } else {
        element.children$ = combineLatest([
          this.store.pipe(select(ScopingSelectors.selectScopesByFolder(element.entity.identity.id))),
          this.columns$,
        ]).pipe(
          map(([scopes, userPreferences]) => {
            return this.mappingService.transformScopeArray(scopes, userPreferences)
          })
        )

        this.store.dispatch(ScopeActions.getScopesByFolder({ folderId: element.entity.identity.id }))
      }
    }
  }

  onSelect(element: MappedEntity<any>) {
    if (element.entity instanceof ScopeVersion) {
      this.scopeTabService.resetTableStates()
      if (element.entity.identity.identificationType === 'SCOPE_BY_ROLE') {
        this.router.navigateByUrl(`/scopes/scope-by-role-overview/${element.entity.identity.id}`);
      } else {
        this.router.navigateByUrl(`/scopes/scope-overview/${element.entity.identity.id}`);
      }
    } else if (element.entity instanceof FolderVersion) {
      this.router.navigateByUrl('/scopes/folder-overview/' + element.entity.identity.id);
    }
  }

  onDuplicateScope(scope: ScopeVersion) {
    if (scope.identity.isTemplate) {
      this.router.navigateByUrl('/duplicate-template/' + scope.identity.id)
    } else {
      this.router.navigateByUrl('/duplicate-scope/' + scope.identity.id)
    }
  }

  onDuplicateSow(sow: FolderVersion) {
    this.router.navigateByUrl('/duplicate-folder/' + sow.identity.id)
  }

  onShowCloseScopeDialog(scope: ScopeVersion) {
    let modal = this.dialog.open(ScopeUiModalComponent, {
      data: new ModalConfig(
        `Close ${this.lang.get('scope')}`,
        `Add a note here to help other collaborators understand your work on this ${this.lang.get('scope')}`,
        undefined,
        undefined,
        (form: FormControl) => {
          this.store.dispatch(
            ScopeActions.closeScope({ scopeId: scope.identity.id, comment: form.get('comment')?.value })
          )
          modal.close()
        },
        undefined,
        [{ name: 'comment', control: new FormControl(''), type: 'textarea', placeholder: 'Add text here' }]
      ),
    })
  }

  onShowArchiveDialog(scope: ScopeVersion) {
    let modal = this.dialog.open(ScopeUiModalComponent, {
      data: new ModalConfig(
        `${scope.archived ? 'Unarchive ' : 'Archive '} ${
          scope.identity.isTemplate ? 'Template' : this.lang.get('scope')
        }`,
        `Are you sure you want to ${scope.archived ? 'unarchive' : 'archive'} the ${
          scope.identity.isTemplate ? 'Template' : this.lang.get('scope')
        }?`,
        scope.archived ? 'Unarchive' : 'Archive',
        undefined,
        () => {
          if (!scope.archived) {
            this.store.dispatch(ScopeActions.archiveScope({ scopeId: scope.identity.id }))
          } else {
            this.store.dispatch(ScopeActions.unarchiveScope({ scopeId: scope.identity.id }))
          }
          modal.close()
        }
      ),
    })
  }

  onShowArchiveSowDialog(sow: FolderVersion) {
    let modal = this.dialog.open(ScopeUiModalComponent, {
      data: new ModalConfig(
        `${sow.identity.archived ? 'Unarchive ' : 'Archive '} ${this.lang.get('scope_of_work')}`,
        `Are you sure you want to ${sow.identity.archived ? 'unarchive' : 'archive'} the ${this.lang.get(
          'scope_of_work'
        )}?`,
        sow.identity.archived ? 'Unarchive' : 'Archive',
        undefined,
        () => {
          if (!sow.identity.archived) {
            this.store.dispatch(ScopeActions.archiveSow({ sowId: sow.identity.id }))
          } else {
            this.store.dispatch(ScopeActions.unarchiveSow({ sowId: sow.identity.id }))
          }
          modal.close()
        }
      ),
    })
  }

  onShowDeleteDialog(scope: FolderVersion | ScopeVersion) {
    if (this.isScopeOfWork(scope)) {
      let modal = this.dialog.open(ScopeUiModalComponent, {
        data: new ModalConfig(
          `Delete ${this.lang.get('scope_of_work')} ${scope.name}`,
          `Are you sure you want to delete ${scope.name} ${this.lang.get('scope_of_work')}?`,
          'Delete',
          undefined,
          () => {
            this.store.dispatch(ScopeActions.deleteFolder({ folderId: scope.identity.id }))
            modal.close()
          }
        ),
      })
    } else {
      let modal = this.dialog.open(ScopeUiModalComponent, {
        data: new ModalConfig(
          `Delete ${scope.identity.isTemplate ? 'Template' : this.lang.get('scope')} ${scope.name}`,
          `Are you sure you want to delete ${scope.name} ${
            scope.identity.isTemplate ? 'Template' : this.lang.get('scope')
          }?`,
          'Delete',
          undefined,
          () => {
            this.store.dispatch(ScopeActions.deleteScope({ scopeId: scope.identity.id }))
            modal.close()
          }
        ),
      })
    }
  }

  onMoveSingleScopeToFolder(scope: ScopeVersion) {
    this.store.dispatch(ScopeActions.getAllFolders({}))

    let modal = this.dialog.open(ScopeUiModalComponent, {
      data: new ModalConfig(
        `Confirm movement of ${scope.name}`,
        undefined,
        undefined,
        undefined,
        (form: FormControl, selections: any[]) => {
          let folder: FolderVersion = selections[0]

          if (!folder.isStateDraft()) {
            this.snackbarService.showSnackbar(
              `Unable to move ${this.lang.get('scope|l')}: '${scope.name}' to ${this.lang.get('sow')}: '${
                folder.name
              }' as ${this.lang.get('sow')} is not in DRAFT state.`
              , SNACKBAR_LENGTH_LONG, SnackbarEventType.WARNING)
          } else if (!this.isRateCardBudgetCompatibleForFolder(scope.identity.rateCard, folder)) {
            this.snackbarService.showSnackbar(
              `Unable to move ${this.lang.get('scope|l')}: '${scope.name}' to ${this.lang.get('sow')}: '${
                folder.name
              }' as they do not share the same currency - ${scope.identity.rateCard.currencyCode}.`
              , SNACKBAR_LENGTH_LONG, SnackbarEventType.WARNING)
          } else {
            this.store.dispatch(ScopeActions.assignScopeToFolder({ scope: scope, folder: folder }))
          }
          modal.close()
        },
        undefined,
        [
          {
            name: 'folder',
            control: new FormControl(''),
            type: 'autocomplete',
            placeholder: 'Choose Folder',
            options$: this.allFolders$,
            displayField: 'name',
            multiselect: false
          }
        ],
        undefined,
        undefined,
        undefined,
        (form: FormControl, selections: any[]) => {
          return !!selections[0]
        }
      ),
    })
  }

  onMoveSingleScopeOutOfFolder(scope: ScopeVersion) {
    var scopeId = scope.identity.id

    if (scope.scopeOfWorkVersion.status != 'DRAFT') {
      this.snackbarService.showSnackbar(`Action not allowed! ${this.lang.get('sow')} must be in 'Draft' status.`, SNACKBAR_LENGTH_LONG, SnackbarEventType.WARNING)
    } else if (scope.useRetainedHours) {
      this.snackbarService.showSnackbar(
        `This single ${this.lang.get('scope|l')} has retained hours and could not be moved out of the ${this.lang.get(
          'scope_of_work'
        )}.`
        , SNACKBAR_LENGTH_LONG, SnackbarEventType.WARNING)
    } else {
      let modal = this.dialog.open(ScopeUiModalComponent, {
        data: new ModalConfig(
          `Move single ${this.lang.get('scope|l')} out from ${this.lang.get('sow')}`,
          `Are you sure you wish to move out '${scope.name}' from '${scope.scopeOfWorkVersion.name}'?`,
          'Move',
          undefined,
          () => {
            this.store.dispatch(ScopeActions.unassignScopeFromFolder({ scopeId: scopeId }))
            modal.close()
          }
        ),
      })
    }
  }

  isScopeOfWork(scope: any) {
    return scope instanceof FolderVersion
  }

  isRateCardBudgetCompatibleForFolder(rateCard: any, folder: FolderVersion) {
    var budgets = folder.budgets?.map((sowBudget) => sowBudget.budget.currency)
    return budgets != null && budgets.length > 0 && budgets.indexOf(rateCard.currencyCode) >= 0
  }

  removeFilter(key: string) {
    this.filters = {
      ...this.filters,
      [key]: ScopeFilterDefaults[key],
    }

    this.resetAndGetScopes(this.filters)
  }

  getDisplayFilters() {
    return Object.entries(this.filters).filter(([key, value]) => !isEqual(value, ScopeFilterDefaults[key]) &&
      ['isTemplate', 'name', 'newCreatedSince', 'approvedSince', 'toReviewAndSignSince', 'toReviewAndSignSelected', 'approvedSelected', 'newScopeCreatedSelected'].indexOf(key) < 0
    )
  }

  protected readonly Array = Array;
}
