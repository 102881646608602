import { Type } from 'class-transformer';
import { ThirdPartyCostUnitType, OvertimeUnitType, ThirdPartyCostFormulaDefinitionDetail, ScopeThirdPartyCostUnitTypeConverter } from './third-party-cost.model';



export class ThirdPartyCostFormula {
  private id!: number
  private name!: string
  private subjectName: string
  quantity: number
  unitType: ThirdPartyCostUnitType
  unitTypeQuantity: number
  overtimeRate: number
  overtimeUnitType: OvertimeUnitType
  overtimeUnitQuantity: number
  @Type(() => ThirdPartyCostFormulaDefinitionDetail) thirdPartyCostFormulaDefinitionSnapshot: ThirdPartyCostFormulaDefinitionDetail
  @Type(() => ScopeThirdPartyCostUnitTypeConverter) private unitTypeConvertors: ScopeThirdPartyCostUnitTypeConverter[]

  get getUnitTypeConvertors() {
    return this.unitTypeConvertors;
  }

  get getName() {
    return this.name;
  }

  get getSubjectName() {
    return this.subjectName;
  }
}
