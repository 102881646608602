import { Component, Inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common'
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog'
import { SharedModule } from '@app/shared/shared.module'
import { ScopeUiDatepickerComponent } from '@app/shared/components/ui-components/scope-ui-datepicker/scope-ui-datepicker.component'
import { ScopeUiAutocompleteComponent } from '@app/shared/components/ui-components/scope-ui-autocomplete/scope-ui-autocomplete.component'
import { MatDatepickerModule } from '@angular/material/datepicker'
import { ScopeVersion } from '@app/core/model/scope-version'
import { CKEditorModule } from '@ckeditor/ckeditor5-angular'
import { ScopeUiInputComponent } from '@shared/components/ui-components/scope-ui-input/scope-ui-input.component'
import { NgxSliderModule } from '@angular-slider/ngx-slider'
import { NgxMaskDirective } from 'ngx-mask'
import { ScopeOverviewService } from '@app/features/scope-overview/service/scope-overview.service';
import { ThirdPartyCost } from '@app/features/scoping/models/third-party-cost.model';
import { ThirdPartyCostFormula } from '@app/features/scoping/models/third-party-cost-formula.model';
import {
  ScopeUiDropdownComponent
} from '@shared/components/ui-components/scope-ui-dropdown/scope-ui-dropdown.component';
import { FormControl } from '@angular/forms';
import { Money } from '@app/features/scope-overview/model/money.model';
import { ScopeOverviewActions } from '@app/features/scope-overview/store/actions/scope-overview.action';
import { Store } from '@ngrx/store';
import { SNACKBAR_LENGTH_LONG, SnackbarEventType, SnackbarService } from '@shared/utils/snackbar.service';
import { plainToInstance } from 'class-transformer';
import { ThirdPartyCostType } from '@app/features/scoping/models/third-party-cost-type.model';

export interface AddTpcModalConfig {
  currentScope: ScopeVersion
}

@Component({
  selector: 'add-tpc-modal',
  standalone: true,
  imports: [CommonModule, ScopeUiDatepickerComponent, ScopeUiAutocompleteComponent, SharedModule, MatDatepickerModule, CKEditorModule, ScopeUiInputComponent, NgxSliderModule, NgxMaskDirective, ScopeUiDropdownComponent],
  templateUrl: './add-tpc-modal.component.html',
  styleUrls: ['./add-tpc-modal.component.scss'],
})
export class AddTpcModalComponent implements OnInit {
  searchText: string
  editDescription: boolean = false
  newThirdPartyCost: ThirdPartyCost = new ThirdPartyCost()
  costControl = new FormControl()
  markupControl = new FormControl()
  priceControl = new FormControl()
  formulas: ThirdPartyCostFormula[]
  thirdPartyCosts: ThirdPartyCostType[]
  isNewCost = true
  loading = true

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: AddTpcModalConfig,
    private scopeOverviewService: ScopeOverviewService,
    private store: Store,
    private snackbarService: SnackbarService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.scopeOverviewService.getThirdPartyCostFormulaDefinition().subscribe({
      next: (formulas) => {
        this.formulas = formulas
        this.formulas.unshift(plainToInstance(ThirdPartyCostFormula, { name: 'None', id: null }))
        this.loading = false
      },
      error: this.snackbarService.showDefaultErrorSnackbar
    })
  }

  displayName(item: any): string {
    return item?.name || '';
  }

  onNameChange(event: string) {
    this.newThirdPartyCost.name = event
  }

  onCostChange(event: number) {
    if (this.costControl.getRawValue() == null) {
      this.priceControl.setValue(event);
    } else {
      let price = Number(this.costControl.getRawValue()) + Number(((this.markupControl.getRawValue() / 100) * this.costControl.getRawValue()));
      this.priceControl.setValue(Number(parseFloat((price).toString()).toFixed(2)));
    }
  }

  onMarkUpChange() {
    if (this.markupControl.getRawValue() == null) {
      this.priceControl.setValue(this.costControl.getRawValue());
    } else {
      let price = (this.costControl.getRawValue() == null) ?
        null : Number(this.costControl.getRawValue()) + Number((this.markupControl.getRawValue() / 100) * this.costControl.getRawValue())
      this.priceControl.setValue(Number(parseFloat((price).toString()).toFixed(2)));
    }
  }

  onPriceChange() {
    let sellingPrice = this.priceControl.getRawValue();
    let unitCost = this.costControl.getRawValue()
    let diff = sellingPrice - unitCost;
    this.markupControl.setValue(
    Number(parseFloat(((diff / unitCost) * 100).toString()).toFixed(2)));
  }

  onSelectFormula(event: any) {
    if (!event.id) return
    this.newThirdPartyCost.formula = event
    this.newThirdPartyCost.formula.quantity = 1;
    this.newThirdPartyCost.formula.unitTypeQuantity = 1;
  }

  onSelectCost(event: { event: ThirdPartyCostType }) {
    if (event.event) {
      this.newThirdPartyCost = plainToInstance(ThirdPartyCost, {
        ...event.event,
        id: null,
        cost: Money.of(event.event.unitCost, this.data.currentScope.identity.rateCard.currencyCode),
        quantity: 1,
        markupPercentageDecimal: event.event.markupPercentage * 100,
        type: { id: event.event.id, name: event.event.name }
      })
    } else {
      this.newThirdPartyCost = new ThirdPartyCost()
    }
  }

  disableSubmit(): boolean {
    if (this.isNewCost) {
      return !this.priceControl.getRawValue() || !this.costControl.getRawValue() || !this.markupControl.getRawValue() || !this.newThirdPartyCost.description
    } else {
      return !this.newThirdPartyCost.type
    }
  }

  submit() {
    if (this.isNewCost) {
      this.newThirdPartyCost.type = {
        id: 0,
        name: this.newThirdPartyCost.name
      }
      this.newThirdPartyCost.cost = Money.of(this.costControl.getRawValue(), this.data.currentScope.identity.rateCard.currencyCode)
      this.newThirdPartyCost.quantity = 1
      this.newThirdPartyCost.markupPercentageDecimal = this.markupControl.getRawValue()
      this.newThirdPartyCost.sourceType = 'SCOPE'
    }
    this.scopeOverviewService.addScopeThirdPartyCost(this.data.currentScope.identity.id, this.newThirdPartyCost).subscribe({
      next: (resp) => {
        this.store.dispatch(ScopeOverviewActions.createScopeTpcSuccess({thirdPartyCost: resp}))
      }, error: () => this.snackbarService.showSnackbar("Error during saving Scope TPC", SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
    })
    this.dialog.closeAll()
  }

  switch() {
    this.isNewCost = !this.isNewCost

    if (!this.isNewCost && !this.thirdPartyCosts) {
      this.loading = true
      this.scopeOverviewService.getThirdPartyCostGroups(true).subscribe({
        next: (groups) => {
          this.thirdPartyCosts = groups
            .flatMap(g => g.typeOptions)
            .sort((a, b) => a.name.localeCompare(b.name))
          this.loading = false
        },
        error: this.snackbarService.showDefaultErrorSnackbar
      })
    }
  }
}
