import { ChangeDetectorRef, Component } from '@angular/core';
import { SafePipe } from "@app/shared/pipe/safe.pipe";
import { AuthService } from '@core/service/auth.service'
import { OutputTemplate } from '@app/features/scope-overview/model/output-template.model';
import { User } from '@core/model/user.model';
import { CompanyManagementService } from '@app/features/company-management/service/company-management.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, debounceTime, map } from 'rxjs';
import { skip } from 'rxjs/operators';
import { downloadFile, untilDestroyed } from '@shared/utils/utils';
import {
  ParentChildOutputWhitelistInfo
} from '@app/features/scope-overview/components/change-scope-output-modal/change-scope-output-modal.component';
import { OutputField } from '@app/features/scope-overview/model/output-field.model';
import { SNACKBAR_LENGTH_LONG, SnackbarEventType, SnackbarService } from '@shared/utils/snackbar.service';
import { UploadModalComponent } from '@shared/components/upload-modal/upload-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { PreviewOutputComponent } from '@shared/components/preview-output/preview-output.component';
import { UploadType } from '@app/features/company-management/models/upload-modal-config';

@Component({
  selector: 'document-template',
  templateUrl: './document-template.component.html',
  styleUrls: ['./document-template.component.scss'],
  providers: [ SafePipe ],

})
export class DocumentTemplateComponent {
  private readonly destroy$;
  id!: number;
  outputTemplate: OutputTemplate
  loggedInUser: User
  loading: boolean = true
  requestSubject: BehaviorSubject<{ template: OutputTemplate, download: boolean }>
  templateWhitelist: ParentChildOutputWhitelistInfo
  updateInProgress: boolean

  constructor(private authService: AuthService,
              private manageService: CompanyManagementService,
              private route: ActivatedRoute,
              private cdr: ChangeDetectorRef,
              private router: Router,
              private snackbarService: SnackbarService,
              private dialog: MatDialog) {
    this.destroy$ = untilDestroyed();
    this.loggedInUser = this.authService.loggedInUser
    this.id = parseInt(route.snapshot.queryParamMap.get("id"))
    this.retrieveOutputTemplate()
    this.retrieveWhitelist()
  }

  retrieveOutputTemplate() {
    this.manageService.getWordTemplate(this.id).subscribe(template => {
      this.outputTemplate = template
      this.loading = false
      this.cdr.detectChanges()
      this.handleRequests()
    })
  }

  handleRequests() {
    this.requestSubject = new BehaviorSubject<{ template: OutputTemplate, download: boolean }>({
      template: this.outputTemplate, download: false
    });
    this.requestSubject.pipe(
      this.destroy$(),
      skip(1),
      debounceTime(1500),
      map((request: { template: OutputTemplate, download: boolean }) => {
        return request.download ? this.updateAndDownload(request.template) : this.sendUpdateRequest(request.template)
      })
    ).subscribe()
  }

  retrieveWhitelist() {
    this.manageService.getParentOutputTemplatesWhitelist(this.loggedInUser.company.id).subscribe(whitelist => {
      this.templateWhitelist = whitelist
    });
  }

  public sendUpdateRequest(template: OutputTemplate) {
    return this.manageService.updateCompanyCustomOutputTemplate(template).subscribe({
      next: () => {
        this.updateInProgress = false
        this.cdr.detectChanges()
      }
    });
  }

  private sendDownloadRequest(template: OutputTemplate, downloadFileName?: string) {
    this.updateInProgress = false
    this.cdr.detectChanges()
    if (template.templateType === "SCOPE") {
      this.manageService.exportScopeOutputToDocx(template.id, downloadFileName).subscribe((res) => {
        downloadFile(res);
      });
    } else {
      this.manageService.exportFolderOutputToDocx(template.id).subscribe((res) => {
        downloadFile(res);
      });
    }
  }

  private updateAndDownload(template: OutputTemplate) {
    if (this.outputTemplate.company.id !== this.loggedInUser.company.id) {
      return this.sendDownloadRequest(template)
    } else {
      return this.manageService.updateCompanyCustomOutputTemplate(template).subscribe({
        next: () => {
          this.sendDownloadRequest(template)
        }
      });
    }
  }

  updateTemplate(template: OutputTemplate, download: boolean = false) {
    this.requestSubject.next({ template, download });
  }

  deleteField(field: OutputField) {
    this.outputTemplate.outputFields.splice(this.outputTemplate.outputFields.indexOf(field), 1);
    this.requestSubject.next({ template: this.outputTemplate, download: false });
  }

  uploadBaseDocx() {
    let dialog = this.dialog.open(UploadModalComponent, {
        data: {
          title: `Upload Docx Base Template`,
          body: `Upload Docx Base Template`,
          type: UploadType.WORD_OUTPUT,
          submitFn: (file: File) => this.manageService.uploadCustomTemplateDocxBaseTemplate(this.id, file),
          successFn: (result: OutputTemplate) => {
            dialog.close()
            this.outputTemplate = result
            this.cdr.detectChanges()
          },
          showRemoveOption: this.outputTemplate.baseDocxTemplate,
          removeOptionText: 'To revert to default base template:',
          removeFn: () => {
            this.manageService.removeCustomTemplateDocxBaseTemplate(this.id).subscribe({
              next: (template: OutputTemplate) => {
                dialog.close()
                this.outputTemplate.baseDocxTemplate = null
                this.outputTemplate.outputFields = template.outputFields
                this.cdr.detectChanges()
              }
            })
          }
        }
      }
    )
  }

  previewExport() {
    let dialog = this.dialog.open(PreviewOutputComponent, {
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      width: '100%',
    });

    dialog.afterClosed().subscribe(() => document.body.classList.remove('cdk-global-scrollblock'));
    document.body.classList.add('cdk-global-scrollblock');
    dialog.componentInstance.outputTemplateName = this.outputTemplate.name;
    dialog.componentInstance.templateLanguage = this.outputTemplate.language;

    dialog.componentInstance.onDownloadExport.subscribe((fileName: string) => {
      this.sendDownloadRequest(this.outputTemplate, fileName)
    });

    if (this.outputTemplate.company.id !== this.loggedInUser.company.id) {
      this.manageService.previewExport(this.outputTemplate.id).subscribe({
        next: (response) => {
          dialog.componentInstance.documentPreview = response;
        },
        error: () => {
          this.snackbarService.showDefaultErrorSnackbar()
          dialog.componentInstance.loading = false;
          dialog.componentInstance.errorState = true;
        }
      });
    } else {
      this.manageService.updateCompanyCustomOutputTemplate(this.outputTemplate).subscribe(() => {
        this.manageService.previewExport(this.outputTemplate.id).subscribe({
          next: (response) => {
            dialog.componentInstance.documentPreview = response;
          },
          error: () => {
            this.snackbarService.showDefaultErrorSnackbar()
            dialog.componentInstance.loading = false;
            dialog.componentInstance.errorState = true;
          }
        });
      });
    }
  }

  duplicate(name: string) {
    this.manageService.duplicateCompanyCustomOutputTemplate(this.outputTemplate.id, name).subscribe({
      next: () => {
        this.snackbarService.showSnackbar(`Template ${this.outputTemplate.name} successfully duplicated`, SNACKBAR_LENGTH_LONG, SnackbarEventType.SUCCESS);
        this.backToGallery()
      }
    })
  }

  backToGallery() {
    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: { 'tab': 'output', 'type': 'document' }
      }
    )
  }
}
