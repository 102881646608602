<mat-sidenav-container>
  <mat-sidenav
    mode="side"
    #sidenav
    [position]="'end'">
    <find-replace
      *ngIf="setReplace"
      [isLoading]="isLoading"
      [availableDeliverables]="availableDeliverables"
      [availableComponents]="availableComponents"
      [availableDepartments]="availableDepartments"
      [availableRoles]="availableRoles"
      [filteredRoles]="filteredRoles"
      [breadcrumbs]="breadcrumbs"
      [deliverablesCount]="deliverablesCount"
      [revertSummaryResponse]="revertSummaryResponse"
      [replaceSummarySuccess]="replaceSummarySuccess"
      [replaceSummaryResponse]="replaceSummaryResponse"
      [revertSummarySuccess]="revertSummarySuccess"
      [currencyCode]='scopeVersion.getRateCardCurrency()'
      (onSearch)="onSearchEvent($event)"
      (onSelectionChange)="onSelectionChangeEvent($event)"
      (onSearchReplaceRoleEvent)="onSearchReplaceRole($event)"
      (onSearchKeyPressedEvent)="onSearchKeyPressed($event)"
      (onRevert)="onRevertEvent($event)"
      (onSubmitFindReplace)="submitFindReplace($event)"
      (onCloseDrawer)="closeSideNav()" />
  </mat-sidenav>

  <mat-sidenav-content>
    <div class="flex flex-row justify-end mx-10 items-center">
      <scope-ui-tabs
        [hidden]='_showFilterView'
        [has-setting]="'TIMELINE_VIEW__ENABLE'"
        class='flex-1'
        [fontSize]="'14px'"
        (onSelect)="currentTab = $event">
        <scope-ui-tab
          tabTitle="Table"
          class="scope-tab white-background"
          [active]="currentTab === 'Table'"
          [icon]="'table'" >
        </scope-ui-tab>
        <scope-ui-tab
          tabTitle="Timeline"
          class="scope-tab white-background"
          [active]="currentTab === 'Timeline'"
          [icon]="'view_timeline'" >
        </scope-ui-tab>
      </scope-ui-tabs>
      <span
        *ngIf="_showFilterView"
        class="filtered-count flex-1"
      >{{
        filteredSectionsCount +
        filteredDeliverablesCount +
        filteredComponentsCount +
        filteredDepartmentsCount +
        filteredRolesCount +
        filteredFeesCount +
        filteredTPCsCount
        }}
        results{{ filters.name ? ' for "' + filters.name + '"' : '' }}</span
      >
      <button
        *ngIf="!_showFilterView && currentTab === 'Timeline'"
        class="text-link ml-4 mr-4"
        aria-label="Show financials"
        (click)="showFinancials = !showFinancials"
        onclick="this.blur()">
        <mat-icon class="material-symbols-rounded icon-small orange">{{showFinancials ? 'visibility_off' : 'visibility'}}</mat-icon>
        {{showFinancials ? 'Hide Financials' : 'Show Financials'}}
      </button>
      <scope-ui-input
        [hasPlaceholder]="false"
        [inputPlaceholder]="''"
        [icon]="'search'"
        [inputLabel]="'Search'"
        (onEnter)="onSearchKeyPressed($event)"
        [inputClass]="'search-field'" />
      <button
        class="text-link ml-4 mr-4"
        aria-label="Show filters"
        (click)="onSelectFilters()"
        onclick="this.blur()">
        Show Filters
        <mat-icon class="material-symbols-rounded orange">arrow_forward</mat-icon>
      </button>
      <scope-ui-options-menu
        *ngIf="scopeApprovalFlowService.isScopeEditable(scopeVersion)"
        [buttonText]="'Create new'"
        [menuOptions]="scopeActions" />

      <button
        class="scope-actions-button"
        (click)="$event.stopPropagation()"
        #menuTrigger="matMenuTrigger"
        [matMenuTriggerFor]="scopeOptionsMenu">
        <mat-icon class="material-symbols-rounded">more_vert</mat-icon>
      </button>
      <mat-menu
        #scopeOptionsMenu="matMenu"
        backdropClass="scope-options-menu">
        <ng-container *ngFor="let option of scopeOptions">
          <button
            (click)="option.click()"
            *ngIf="option.isVisible ? option.isVisible(scopeVersion) : true"
            mat-menu-item
            class="scope-option">
            <mat-icon class="material-symbols-rounded orange">{{ option.icon }}</mat-icon>
            <span>{{ option.name }}</span>
          </button>
        </ng-container>
      </mat-menu>
    </div>
    <mat-divider></mat-divider>
    <div class="flex flex-wrap mx-10" *ngIf="getDisplayFilters() as displayFilters">
      <ng-container *ngFor="let filter of displayFilters">
        <div class="filter-tag">
          <span>{{ scopeFilterConfig[filter[0]] }} </span>
          <span class="tag-value">{{ Array.isArray(filter[1]) ? filter[1]?.join(', ') : filter[1]?.toString() }}</span
          ><mat-icon
          class="material-symbols-rounded"
          (click)="removeFilter(filter[0])"
        >close</mat-icon
        >
        </div>
      </ng-container>
      <button
        *ngIf="displayFilters.length > 0"
        mat-button
        class="clear-all-tag"
        aria-label="Clear all filters"
        (click)="resetFilters()">
        Clear all
        <mat-icon
          iconPositionEnd
          class="material-symbols-rounded orange"
        >close</mat-icon
        >
      </button>
    </div>

    <div *ngIf="!_showFilterView && currentTab === 'Table'" class="mt-4 mx-6.5 mb-12">
      <scope-table
        [currentUser]="currentUser"
        [scopeVersion]="scopeVersion"
        [feeMenuOptions]="feeMenuOptions"
        [currentRatecard]="currentRatecard"
        [mainColumnClass]='mainColumnClass'
        [componentMainColumnClass]='componentMainColumnClass'
        [sortedSections]='sortedSections$ | async'
        [sortedDefaultSectionDeliverables]='sortedDefaultSectionDeliverables$ | async'
        [cdnConfig]="cdnConfig"
        (onAddSectionFee)="addSectionFee($event)"
        (onDeleteSection)="deleteSection($event)"
        (onFetchDeliverable)="fetchDeliverable($event)"
        (onUpdateDeliverable)="updateDeliverable($event)"
        (onUpdateComponent)="updateComponent($event)"
        (onDuplicateDeliverable)="duplicateDeliverable($event)"
        (onDeleteDeliverable)="deleteDeliverable($event)"
        (onAcceptDeliverable)="acceptDeliverable($event)"
        (onRejectDeliverable)="rejectDeliverable($event)"
        (onAddDeliverableFee)="addDeliverableFee($event)"
        (onAddComponentFee)="addComponentFee($event)"
        (onEditDeliverable)="editDeliverable($event)"
        (onCompleteTrade)="completeTrade($event)"
        (onTradeDeliverable)="tradeDeliverable($event)"
        (onStopProgressTrade)="stopProgressTrade($event)"
        (onReopenTrade)="reopenTrade($event)"
        (onCancelTrade)="cancelTrade($event)"
        (onConfirmTrade)="confirmTrade($event)"
        (onAddDeliverableToTrade)="addDeliverableToTrade($event)"
        (onShowDeliverableApplyLocationCardDialog)="showDeliverableApplyLocationCardDialog($event)"
        (onUpdateFeeItem)="updateFeeItem($event.feeItem, $event.section, $event.deliverable, $event.component)"
        (onOverrideQuantity)="overrideQuantity($event)"
        (onOverrideRateCardRoleHours)="overrideRateCardRoleHours($event)"
        (onMoveComponent)="moveComponent($event)"
        (onMoveComponentOutsideDeliverable)="moveComponentOutsideDeliverable($event)"
      >

      </scope-table>

      <div class="mt-2 mx-3.5">
        <scope-ui-table
          [has-setting]="'FEE_ITEM__ENABLE&&SCOPE__ENABLE_FEES'"
          [has-privilege]="'SCOPE__VIEW_FEES'"
          [loggedInUser]='currentUser'
          [tableClass]="'fees-table'"
          [headerExpandable]="true"
          [data]="(scopeFees$ | async) | orderBy : 'entity.id'"
          [displayedColumns]="scopeTabService.scopeFeeColumns"
          [addToggleListColumn]="true"
          [noSort]="true"
          [noPagination]="true"
          [noPreferences]="true"
          [showAddButton]="scopeApprovalFlowService.isScopeEditable(scopeVersion) && currentUser.hasPrivilege('SCOPE__CREATE_FEES')"
          [expandable]="false"
          (addClicked)="addScopeFee()"
          [showToggleMenu]="feeMenuOptions.length > 0"
          [menuOptions]="feeMenuOptions"
          (updateFeeItem)="updateFeeItem($event)"
          [showFooterRow]="true"
          [parentEntity]="scopeVersion">
        </scope-ui-table>
      </div>

      <div class="wrapper font-newJuneBold mx-3.5">
        <div class="block-wrapper py-0" *ngIf="scopeVersion.discount" [has-privilege]="'SCOPE__VIEW_RATES'" [has-privilege-entity]="scopeVersion">
          <div class="column ten--columns">
            <commentable [c-entity]="scopeVersion" [c-key]="'discountName'" [c-type]="'scope'">{{
            scopeVersion.discount.getDescription() }}
              {{scopeVersion.discount.getType() ==
              'PERCENTAGE' ? '(' + scopeVersion.discount.getValueFormatted() + '%)' : '' }}
            </commentable>
            <span class="grey m_top_5 f-style-builder">Discount</span>
          </div>
          <div class="column one--column center text-m">
            <commentable [c-entity]="scopeVersion" [c-key]="'discountValue'" [c-type]="'scope'">
              <span *ngIf="scopeVersion.hasPrivilege(Privilege.SCOPE__VIEW_RATES, currentUser)">
                  {{getDiscountValue(scopeVersion) | currency: scopeVersion.getRateCardCurrency()}}
              </span>
              <span *ngIf="!scopeVersion.hasPrivilege(Privilege.SCOPE__VIEW_RATES, currentUser)">--</span>
            </commentable>
          </div>

          <div class="column one--column">
            <div class="flex flex-row justify-end" [has-privilege]="scopeVersion.identity.isTemplate ? 'SCOPE_TEMPLATE__EDIT && SCOPE__VIEW_RATES':'SCOPE_DISCOUNT__EDIT && SCOPE__VIEW_RATES'" *ngIf="scopeApprovalFlowService.isScopeEditable(scopeVersion) && scopeVersion.hasPrivilegeValueRestriction(Privilege.SCOPE_DISCOUNT__EDIT, getDiscountValue(scopeVersion), currentUser)">
              <button
                class="toggle-button ml-1"
                (click)="editDiscount()"
                mat-mini-fab>
                <mat-icon class="material-symbols-rounded">edit</mat-icon>
              </button>
              <button
                class="toggle-button ml-1"
                (click)="deleteDiscount()"
                mat-mini-fab>
                <mat-icon class="material-symbols-rounded">delete</mat-icon>
              </button>
            </div>
          </div>
        </div>

        <div class="block-wrapper py-0" *ngIf="scopeVersion.msaLineItem">
          <div class="column ten--columns">
            <commentable [c-entity]="scopeVersion" [c-key]="'msaLineItemName'" [c-type]="'scope'">{{
              scopeVersion.msaLineItem.getName() }}
              {{scopeVersion.msaLineItem.getType() ==
              'PERCENTAGE' ? '(' + scopeVersion.msaLineItem.getValueFormatted() + '%)' : '' }}
            </commentable>
            <span class="grey m_top_5 f-style-builder">MSA Line Item</span>
          </div>
          <div class="column one--column center text-m">
            <commentable [c-entity]="scopeVersion" [c-key]="'msaLineItemValue'" [c-type]="'scope'">{{scopeVersion.msaLineItem.chargeType == "FEE" ? '' : '-'}}
              {{scopeVersion.msaLineItem.getType() == 'PERCENTAGE' ?
                (scopeVersion.msaLineItem.getValueByPercentage(scopeVersion.getTotalSellingPrice({ excludeFees: false, excludeMsa: true, excludeDiscount: false })?.getValue()) | currency: scopeVersion.getRateCardCurrency()) :
                (scopeVersion.msaLineItem.getValueFormatted() | currency: scopeVersion.getRateCardCurrency())}}
            </commentable>
          </div>

          <div class="column one--column">
            <div class="flex flex-row justify-end" [has-privilege]="'MSA_LINE_ITEM__EDIT'" *ngIf="scopeApprovalFlowService.isScopeEditable(scopeVersion)">
              <button
                class="toggle-button ml-1"
                (click)="editMsaLineItem()"
                mat-mini-fab>
                <mat-icon class="material-symbols-rounded">edit</mat-icon>
              </button>
              <button
                class="toggle-button ml-1"
                (click)="deleteMsaLineItem()"
                mat-mini-fab>
                <mat-icon class="material-symbols-rounded">delete</mat-icon>
              </button>
            </div>
          </div>
        </div>
          <div class='block-wrapper light-purple' [has-setting]="'SOW__RETAINED_HOURS'"
               *ngIf='!scopeVersion.isScopeByRole() && scopeVersion.scopeOfWorkVersion && showUseRetainedTeamToggle'>
            <div class='column nine--columns'>
              <commentable [c-entity]='scopeVersion' [c-key]="'RetainedTeamLabel'" class='purple' [c-type]="'scope'">
                Retained Hours used {{ isUseRetainedHours() ? ' (On)' : ' (Off)' }}</commentable>
            </div>
            <div class='column one--column'>
              <div class='purple' *ngIf='scopeVersion.useRetainedHours && scopeRetainedHoursStats'>
                {{ scopeRetainedHoursStats.totalHours | formatHours }}
              </div>
            </div>
            <div class='column one--column'>
              <div class='purple' *ngIf='scopeVersion.useRetainedHours && scopeRetainedHoursStats'>
                {{ (scopeRetainedHoursStats.totalSellingPrice.amount | currency : scopeVersion.getRateCardCurrency())}}
              </div>
            </div>
            <div class='column one--column'>
              <mat-checkbox
                [disabled]="scopeVersion.status !== 'DRAFT'"
                value='{{ scopeVersion.useRetainedHours }}'
                [checked]='scopeVersion.useRetainedHours'
                (change)='updateUseRetainedHours($event)'
                aria-label='Use Retained Hours'
              ></mat-checkbox>
            </div>
          </div>

        <div class="block-wrapper light-purple" [has-setting]="'SCOPE__MARGIN_SHOW'"
             [has-privilege]="'SCOPE_MARGIN__VIEW'">
          <div class="column nine--columns">
            <commentable [c-entity]="scopeVersion" [c-key]="'scopeMarginLabel'" class="purple" [c-type]="'scope'">{{'scope' | lang }} Margin</commentable>
          </div>
          <div class="column one--column">
            <div class="purple" [has-privilege]="'SCOPE_MARKUP__VIEW'" matTooltip="Margin">
              {{ scopeVersion.margin ? scopeVersion.margin.toFixed(2) + '%' : '-' }}
            </div>
          </div>
          <div class="column one--column">
            <div class="purple" [has-privilege]="'SCOPE_MARKUP__VIEW'" matTooltip="Profit">
                {{ scopeVersion.getTotalProfit() ? (scopeVersion.getTotalProfit().amount | currency : scopeVersion.getRateCardCurrency()) : 'N/A' }}
            </div>
          </div>
          <div class="column one--column"></div>
        </div>

        <div class="block-wrapper dark-purple white">
          <div class="column flex items-center" [ngClass]="scopeTabService.calculateScopeTotalsMainColumnClass(scopeVersion)">
            <commentable class="inline-block" [c-entity]="scopeVersion" [c-key]="'scopeTotalLabel'" [c-type]="'scope'">{{'scope' | lang }} Totals</commentable>
            <button
              *ngIf="scopeApprovalFlowService.isScopeEditable(scopeVersion) && !scopeVersion.discount"
              [has-privilege]="scopeVersion.identity.isTemplate ? 'SCOPE_TEMPLATE__EDIT && SCOPE__VIEW_RATES':'SCOPE_DISCOUNT__EDIT && SCOPE__VIEW_RATES'"
              type="button"
              class="primary ml-4"
              (click)='addDiscount()'>
              Add discount
            </button>
          </div>

          <div class="column one--column" [has-privilege]="'SCOPE_MARKUP__VIEW'" [has-privilege-entity]="scopeVersion" [hidden]="!scopeTabService.userColumns.MARK_UP.selected">
            <span class="bold blue text center">{{scopeVersion.getMarkup() + '%' }}</span>
          </div>

          <div class="column one--column" [has-privilege]="'SCOPE_MARKUP__VIEW'" [has-privilege-entity]="scopeVersion" [hidden]="!scopeTabService.userColumns.PROFIT.selected">
            <span class="bold blue text center">
              {{scopeVersion.getTotalProfit() ? (scopeVersion.getTotalProfit().getValue() | currency : scopeVersion.getRateCardCurrency()) : '--'}}
            </span>
          </div>

          <div class="column one--column" [hidden]="!scopeTabService.userColumns.SCOPEMARK_HOURS.selected">
            <commentable [c-entity]="scopeVersion" [c-key]="'scopeTotalSMHours'" [c-type]="'scope'">
              {{scopeVersion.getCachedTotalScopeMarkHours() | formatHours}}
            </commentable>
          </div>

          <div class="column one--column" [hidden]="!scopeTabService.userColumns.SCOPEMARK_PRICE.selected">
            <commentable [c-entity]="scopeVersion" [c-key]="'scopeTotalSMPrice'" [c-type]="'scope'">
              <span [has-privilege]="'SCOPE__VIEW_RATES'" [has-privilege-mask]="'--'" [has-setting]="'RATECARD__SCOPEMARK__RATES'" [has-setting-disabled-mask]="'--'">
                {{scopeVersion.totalScopeMarkSellingPrice() ? (scopeVersion.totalScopeMarkSellingPrice().amount | currency : scopeVersion.getRateCardCurrency()) : '--'}}
              </span>
            </commentable>
          </div>

          <div class="column one--column" [hidden]="!scopeTabService.userColumns.AGENCY_HOURS.selected">
            <commentable [c-entity]="scopeVersion" [c-key]="'scopeTotalRateCardHours'" [c-type]="'scope'">
              <span has-setting="SCOPE__AGENCY_HOURS" has-setting-disabled-mask="--">{{scopeVersion.getTotalRateCardHours() | formatHours}}</span>
            </commentable>
          </div>

          <div class="column one--column" [hidden]="!scopeTabService.userColumns.BUDGET.selected">
            <commentable [c-entity]="scopeVersion" [c-key]="'scopeTotalDeliverableBudget'" [c-type]="'scope'">
              <span [has-privilege]="'SCOPE__VIEW_RATES'" [has-privilege-mask]="'--'"
                  matTooltip="Allocated budget of {{scopeVersion.getTotalDeliverableBudget()?.amount | currency: scopeVersion.getRateCardCurrency()}} exceeds scope budget of  {{scopeVersion.budget?.amount | currency: scopeVersion.getRateCardCurrency()}}"
                  [matTooltipDisabled]="scopeVersion.budget?.amount < scopeVersion.getTotalDeliverableBudget()?.amount">
                {{ scopeVersion.getTotalDeliverableBudget()?.amount | currency: scopeVersion.getRateCardCurrency() }}
              </span>
            </commentable>
          </div>
          <div class="column one--column" [hidden]="!scopeTabService.userColumns.BALANCE.selected">
            <commentable [c-entity]="scopeVersion" [c-key]="'scopeTotalBudgetBalance'" [c-type]="'scope'">
              <span matTooltip="Over {{'scope' | lang }} budget of {{scopeVersion.budget?.amount | currency: scopeVersion.getRateCardCurrency()}}" [matTooltipDisabled]="scopeVersion.getBudgetBalance().amount < 0"
                  [has-privilege]="'SCOPE__VIEW_RATES'" [has-privilege-mask]="'--'" [ngClass]="{'highlight-error': scopeVersion.getBudgetBalance().amount < 0 }">
                {{ scopeVersion.getBudgetBalance().amount | currency: scopeVersion.getRateCardCurrency() }}
              </span>
            </commentable>
          </div>
          <div class="column one--column" [hidden]="!scopeTabService.userColumns.AGENCY_PRICE.selected">
            <commentable [c-entity]="scopeVersion" [c-key]="'scopeTotalRateCardPrice'" [c-type]="'scope'">
              <span [has-privilege]="'SCOPE__VIEW_RATES'" [has-privilege-mask]="'--'" *ngIf="!scopeVersion.containsUnmappedScopeMarkRoles">
                {{ scopeVersion.getTotalSellingPrice()?.amount | currency: scopeVersion.getRateCardCurrency() }}
              </span>
              <span *ngIf="scopeVersion.containsUnmappedScopeMarkRoles">
                --
              </span>
            </commentable>
          </div>
          <div class="column one--column"></div>
        </div>

        <div class="block-wrapper dark-purple white" *ngIf="scopeTabService.userColumns.SCOPE__TRAFFICKING__DELIVERABLE_ACTUALS.selected">
          <div class="column ten--columns">
            <p>Total Complete Percentage: {{scopeVersion.totalPercentageComplete()}}%</p>
          </div>
          <div class="column one--column">
            <p>Total Actuals:</p>
          </div>
          <div class="column one--column">
            <p class="flex justify-end">{{scopeVersion.totalActualHours() | formatHours}}</p>
          </div>
        </div>
      </div>
    </div>

    <ng-container *ngIf="!_showFilterView && currentTab === 'Timeline'">
      <div class="financials-panel" *ngIf="showFinancials">
        <div class="flex w-full gap-4">
          <button
            *ngIf='scopeApprovalFlowService.isScopeEditable(scopeVersion) && !scopeVersion.discount'
            class='financials-add-btn'
            [has-privilege]="'SCOPE__VIEW_RATES'"
            (click)='addDiscount()'>
            <mat-icon class="material-symbols-rounded">add</mat-icon>
            Add discount
          </button>
          <scope-ui-financial-card
            *ngIf='scopeVersion.discount'
            [has-privilege]="'SCOPE__VIEW_RATES'"
            [has-privilege-entity]="scopeVersion"
            [heading]="'Discount'"
            [sub_heading]="scopeVersion.discount.getDescription()"
            [value]="scopeVersion.discount.getType() == 'PERCENTAGE' ?
                  (scopeVersion.discount.getValueByPercentage(scopeVersion.getTotalSellingPrice({excludeFees: false, excludeMsa: true, excludeDiscount: true})?.getValue()) | currency: scopeVersion.getRateCardCurrency()) :
                  (scopeVersion.discount.getValueFormatted() | currency: scopeVersion.getRateCardCurrency())"
            [sub_value]="scopeVersion.discount.getType() ==
                'PERCENTAGE' ? scopeVersion.discount.getValueFormatted() + '%' : ''"
            [showMenu]="scopeApprovalFlowService.isScopeEditable(scopeVersion)"
            (edit)="editDiscount()"
            (delete)="deleteDiscount()"
          ></scope-ui-financial-card>
          <scope-ui-financial-card
            [has-setting]="'FEE_ITEM__ENABLE'"
            [has-privilege]="'SCOPE__VIEW_FEES'"
            [heading]="'Total Fees'"
            [value]="scopeVersion.getFeeValueTotal().amount | currency: scopeVersion.getRateCardCurrency()"
            [expandable]="true"
            [headingClass]="'large'"
            [showAddButton]="scopeApprovalFlowService.isScopeEditable(scopeVersion) &&
              currentUser.hasPrivilege('SCOPE__CREATE_FEES') &&
              (currentUser.company.hasApplicationSetting('SCOPE__ENABLE_FEES') ||
              currentUser.company.hasApplicationSetting('SCOPE__ENABLE_SECTION_FEES') ||
              currentUser.company.hasApplicationSetting('DELIVERABLE__ENABLE_FEES') ||
              currentUser.company.hasApplicationSetting('COMPONENT__ENABLE_FEES'))"
            [addButtonLabel]="'Add Fee'"
            [(expanded)]="showTimelineFees"
            (add)="addFee()"
            (expandedChange)="toggleTimelineFees($event)"
          ></scope-ui-financial-card>
          <scope-ui-financial-card
            [heading]="'Total TPCs'"
            [value]="scopeVersion.getTpcValueTotal().amount | currency: scopeVersion.getRateCardCurrency()"
            [expandable]="true"
            [headingClass]="'large'"
            [(expanded)]="showTimelineTpcs"
            (expandedChange)="toggleTimelineTpcs($event)"
          ></scope-ui-financial-card>
          <scope-ui-financial-card
            *ngIf="scopeVersion.msaLineItem"
            [heading]="'MSA'"
            [sub_heading]="scopeVersion.msaLineItem.getName()"
            [value]="scopeVersion.msaLineItem.getType() == 'PERCENTAGE' ?
                  (scopeVersion.msaLineItem.getValueByPercentage(scopeVersion.getTotalSellingPrice({ excludeFees: false, excludeMsa: true, excludeDiscount: false })?.getValue()) | currency: scopeVersion.getRateCardCurrency()) :
                  (scopeVersion.msaLineItem.getValueFormatted() | currency: scopeVersion.getRateCardCurrency())"
            [sub_value]="scopeVersion.msaLineItem.getType() ==
                'PERCENTAGE' ? scopeVersion.msaLineItem.getValueFormatted() + '%' : ''"
            [showMenu]="scopeApprovalFlowService.isScopeEditable(scopeVersion)"
            (edit)="editMsaLineItem()"
            (delete)="deleteMsaLineItem()"
          ></scope-ui-financial-card>
          <scope-ui-financial-card
            [has-privilege]="'SCOPE__VIEW_RATES'"
            [heading]="'Resources'"
            [value]="scopeVersion.getNaturalSellingPrice({includeTpc: false}).amount | currency: scopeVersion.getRateCardCurrency()"
          ></scope-ui-financial-card>
          <scope-ui-financial-card
            *ngIf="scopeVersion.identity.costPlus"
            [has-setting]="'COST_PLUS'"
            [has-privilege]="'SCOPE_MARGIN__VIEW'"
            [heading]="'Cost Plus'"
            [value]="scopeVersion.identity.costPlus.value + '%'"
          ></scope-ui-financial-card>
          <scope-ui-financial-card
            [has-privilege]="'SCOPE__VIEW_RATES'"
            [heading]="'Scope Total'"
            [value]="scopeVersion.containsUnmappedScopeMarkRoles ? '--' :
              scopeVersion.getTotalSellingPrice()?.amount | currency: scopeVersion.getRateCardCurrency()"
            [cardClass]="'purple-400'"
          ></scope-ui-financial-card>
          <scope-ui-financial-card
            [has-privilege]="'SCOPE_MARKUP__VIEW&&SCOPE_MARGIN__VIEW'"
            [heading]="'Margin'"
            [value]="scopeVersion.margin ? scopeVersion.margin.toFixed(2) + '%' : '-'"
            [sub_value]="scopeVersion.getTotalProfit() ? (scopeVersion.getTotalProfit().amount | currency : scopeVersion.getRateCardCurrency()) : 'N/A'"
            [sub_value_label]="'Profit'"
            [cardClass]="'purple-800'"
          ></scope-ui-financial-card>
          <scope-ui-financial-card
            [has-setting]="'SCOPE__TRAFFICKING__DELIVERABLE_ACTUALS'"
            [heading]="'Actuals'"
            [value]="scopeVersion.totalActualHours() | formatHours"
            [sub_value]="scopeVersion.totalPercentageComplete() + '%'"
            [sub_value_label]="'Total Complete Percentage'"
          ></scope-ui-financial-card>
        </div>
        <div class="mt-6" *ngIf="showTimelineFees && allFeesLoaded">
          <scope-ui-table
            [has-setting]="'FEE_ITEM__ENABLE&&SCOPE__ENABLE_FEES'"
            [has-privilege]="'SCOPE__VIEW_FEES'"
            [loggedInUser]='currentUser'
            [tableClass]="'fees-table'"
            [headerExpandable]="false"
            [data]="allFees$ | async"
            [displayedColumns]="scopeTabService.allFeeColumns"
            [addToggleListColumn]="true"
            [noSort]="false"
            [noPagination]="true"
            [noPreferences]="true"
            [showAddButton]="scopeApprovalFlowService.isScopeEditable(scopeVersion) && currentUser.hasPrivilege('SCOPE__CREATE_FEES')"
            [expandable]="false"
            (addClicked)="addFee()"
            [showToggleMenu]="feeMenuOptions.length > 0"
            [menuOptions]="feeMenuOptions"
            (updateFeeItem)="updateFeeItem($event)"
            [showFooterRow]="false"
            [showBreadcrumb]="true"
            (linkElement)='linkFeeItem($event)'>
          </scope-ui-table>
        </div>
        <div class="mt-6" *ngIf="showTimelineFees && !allFeesLoaded">
          <div class="skeleton-loader">
            <div class="skeleton-item"></div>
            <div class="skeleton-item"></div>
          </div>
        </div>
        <div class="mt-6" *ngIf="showTimelineTpcs && allTpcsLoaded">
          <scope-ui-table
            [loggedInUser]='currentUser'
            [tableClass]="'tpc-table'"
            [headerExpandable]="false"
            [data]="allThirdPartyCosts$ | async"
            [displayedColumns]="scopeTabService.thirdPartyCostColumns"
            [noSort]="false"
            [noPagination]="true"
            [noPreferences]="true"
            [showToggleMenu]="false"
            [expandable]="false"
            [showFooterRow]="false"
            [showBreadcrumb]="true">
          </scope-ui-table>
        </div>
        <div class="mt-6" *ngIf="showTimelineTpcs && !allTpcsLoaded">
          <div class="skeleton-loader">
            <div class="skeleton-item"></div>
            <div class="skeleton-item"></div>
          </div>
        </div>
      </div>
      <div class='flex flex-row mx-6.5 flex-1'>
        <div class='flex flex-col flex-1 mt-8'>
          <scope-table
            [isTimeline]='true'
            [currentUser]="currentUser"
            [scopeVersion]="scopeVersion"
            [feeMenuOptions]="feeMenuOptions"
            [currentRatecard]="currentRatecard"
            [mainColumnClass]="'five--columns'"
            [componentMainColumnClass]="'five--columns'"
            [sortedSections]='sortedSections$ | async'
            [sortedDefaultSectionDeliverables]='sortedDefaultSectionDeliverables$ | async'
            [cdnConfig]="cdnConfig"
            (onAddSectionFee)="addSectionFee($event)"
            (onDeleteSection)="deleteSection($event)"
            (onFetchDeliverable)="fetchDeliverable($event)"
            (onUpdateDeliverable)="updateDeliverable($event)"
            (onUpdateComponent)="updateComponent($event)"
            (onDuplicateDeliverable)="duplicateDeliverable($event)"
            (onDeleteDeliverable)="deleteDeliverable($event)"
            (onAcceptDeliverable)="acceptDeliverable($event)"
            (onRejectDeliverable)="rejectDeliverable($event)"
            (onAddDeliverableFee)="addDeliverableFee($event)"
            (onAddComponentFee)="addComponentFee($event)"
            (onEditDeliverable)="editDeliverable($event)"
            (onCompleteTrade)="completeTrade($event)"
            (onTradeDeliverable)="tradeDeliverable($event)"
            (onStopProgressTrade)="stopProgressTrade($event)"
            (onReopenTrade)="reopenTrade($event)"
            (onCancelTrade)="cancelTrade($event)"
            (onConfirmTrade)="confirmTrade($event)"
            (onAddDeliverableToTrade)="addDeliverableToTrade($event)"
            (onShowDeliverableApplyLocationCardDialog)="showDeliverableApplyLocationCardDialog($event)"
            (onUpdateFeeItem)="updateFeeItem($event.feeItem, $event.section, $event.deliverable, $event.component)"
            (onOverrideQuantity)="overrideQuantity($event)"
            (onOverrideRateCardRoleHours)="overrideRateCardRoleHours($event)"
            (onMoveComponent)="moveComponent($event)"
            (onMoveComponentOutsideDeliverable)="moveComponentOutsideDeliverable($event)" >
          </scope-table>
          <button *ngIf="scopeApprovalFlowService.isScopeEditable(scopeVersion)"
                  class="text-link mx-3.5 mt-2 add-item-button" mat-button [matMenuTriggerFor]="menu">
            Add Item <mat-icon class="material-symbols-rounded">add</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <ng-container *ngFor="let option of scopeActions">
              <button [hidden]="option.isHidden?.()" (click)="option?.callback()" class="menu-button" mat-menu-item>
                <i class="material-symbols-rounded">{{ option.icon() }}</i>
                <span> {{ option.name() }}</span>
              </button>
            </ng-container>
          </mat-menu>
        </div>
        <div class='timeline-container'>
          <scope-timeline
            [scopeStartDate]='scopeVersion.startDate'
            [scopeEndDate]='scopeVersion.endDate'
            [sections]='sortedSections$ | async'
            [deliverables]='sortedDefaultSectionDeliverables$ | async'
            [dateFormat]="currentUser.company.companyDateFormat || 'DD/MM/YYYY'"
            [disabled]="!scopeApprovalFlowService.isScopeEditable(scopeVersion)"
            (onUpdateElement)="updateElement($event)"
            (onFetchDeliverable)="fetchDeliverable($event)" >
          </scope-timeline>
        </div>
      </div>
    </ng-container>

    <div class="mx-10" *ngIf="_showFilterView">
      <ng-template #roleSubTable let-element>
        <scope-ui-table
          [tableClass]="'overview-sub-table'"
          style="margin-top: 16px"
          [loggedInUser]='currentUser'
          [data]="element.children$ | async"
          [displayedColumns]="scopeTabService.roleColumns"
          [noPagination]="true"
          [expandable]="false" />
      </ng-template>

      <ng-template #departmentSubTable let-element>
        <scope-ui-table
          [tableClass]="'overview-sub-table'"
          style="margin-top: 16px"
          [loggedInUser]='currentUser'
          [data]="element.children$ | async"
          [displayedColumns]="scopeTabService.departmentColumns"
          [noPagination]="true"
          [expandable]="true"
          (onExpand)="onSelect($event)" >
          <ng-template let-element>
            <ng-container [ngTemplateOutlet]="roleSubTable" [ngTemplateOutletContext]="{$implicit: element}"></ng-container>
          </ng-template>
        </scope-ui-table>
      </ng-template>

      <ng-template #componentSubTable let-element>
        <scope-ui-table
          [tableClass]="'overview-sub-table'"
          style="margin-top: 16px"
          [loggedInUser]='currentUser'
          [data]="element.children$ | async"
          [displayedColumns]="scopeTabService.componentColumns"
          [addToggleListColumn]="true"
          [noPagination]="true"
          [expandable]="true"
          [showDescription]="true"
          (onExpand)="onSelect($event)"
          (onSaveElement)="updateElement($event)"
          [menuOptions]="componentMenuOptions" >
          <ng-template let-element>
            <ng-container [ngTemplateOutlet]="departmentSubTable" [ngTemplateOutletContext]="{$implicit: element}"></ng-container>
          </ng-template>
        </scope-ui-table>
      </ng-template>

      <ng-template #deliverableSubTable let-element>
        <scope-ui-table
          [tableClass]="'overview-sub-table'"
          style="margin-top: 16px"
          [loggedInUser]='currentUser'
          [data]="element.children$ | async"
          [displayedColumns]="scopeTabService.deliverableColumns"
          [addToggleListColumn]="true"
          [noPagination]="true"
          [expandable]="true"
          [hideHeader]="true"
          [showDescription]="true"
          [editAction]="editDeliverableAction"
          (onExpand)="onSelect($event)"
          (onSaveElement)="updateElement($event)"
          [menuOptions]="deliverableMenuOptions" >
          <ng-template let-element>
            <ng-container [ngTemplateOutlet]="componentSubTable" [ngTemplateOutletContext]="{$implicit: element}"></ng-container>
          </ng-template>
        </scope-ui-table>
      </ng-template>

      <scope-ui-table
        *ngIf="(filteredSectionsDataSource$ | async)?.length"
        class="flex"
        [tableClass]="'overview-table'"
        style="margin-top: 16px"
        [loggedInUser]='currentUser'
        [data]="filteredSectionsDataSource$ | async"
        [displayedColumns]="scopeTabService.sectionColumns"
        [addToggleListColumn]="true"
        [noPagination]="true"
        [expandable]="true"
        [showDescription]="true"
        [editDeliverableAction]="editDeliverableAction"
        [maxColumns]="9"
        [showToggleMenu]="scopeApprovalFlowService.isScopeEditable(scopeVersion)"
        [currentScopeStatus]='scopeVersion.status'
        (onExpand)="onSelect($event)"
        (onSaveElement)="updateElement($event)"
        (onToggle)="onToggleScopePreference($event)"
        [menuOptions]="sectionMenuOptions" >
        <ng-template let-element>
          <ng-container [ngTemplateOutlet]="deliverableSubTable" [ngTemplateOutletContext]="{$implicit: element}"></ng-container>
        </ng-template>
      </scope-ui-table>
      <scope-ui-table
        *ngIf="(filteredDeliverablesDataSource$ | async)?.length"
        class="flex"
        [tableClass]="'overview-table'"
        style="margin-top: 16px"
        [loggedInUser]='currentUser'
        [data]="filteredDeliverablesDataSource$ | async"
        [displayedColumns]="scopeTabService.deliverableColumns"
        [addToggleListColumn]="true"
        [noPagination]="true"
        [expandable]="true"
        [showBreadcrumb]="true"
        [editAction]="editDeliverableAction"
        [showDescription]="true"
        [maxColumns]="9"
        [showToggleMenu]="scopeApprovalFlowService.isScopeEditable(scopeVersion)"
        [currentScopeStatus]='scopeVersion.status'
        (onExpand)="onSelect($event)"
        (onSaveElement)="updateElement($event)"
        (onToggle)="onToggleScopePreference($event)"
        [menuOptions]="deliverableMenuOptions" >
        <ng-template let-element>
          <ng-container [ngTemplateOutlet]="componentSubTable" [ngTemplateOutletContext]="{$implicit: element}"></ng-container>
        </ng-template>
      </scope-ui-table>
      <scope-ui-table
        *ngIf="(filteredComponentsDataSource$ | async)?.length"
        class="flex"
        [tableClass]="'overview-table'"
        style="margin-top: 16px"
        [loggedInUser]='currentUser'
        [data]="filteredComponentsDataSource$ | async"
        [displayedColumns]="scopeTabService.componentColumns"
        [addToggleListColumn]="true"
        [noPagination]="true"
        [expandable]="true"
        [showBreadcrumb]="true"
        [showDescription]="true"
        [maxColumns]="9"
        [showToggleMenu]="scopeApprovalFlowService.isScopeEditable(scopeVersion)"
        [currentScopeStatus]='scopeVersion.status'
        (onExpand)="onSelect($event)"
        (onSaveElement)="updateElement($event)"
        [menuOptions]="componentMenuOptions" >
        <ng-template let-element>
          <ng-container [ngTemplateOutlet]="departmentSubTable" [ngTemplateOutletContext]="{$implicit: element}"></ng-container>
        </ng-template>
      </scope-ui-table>
      <scope-ui-table
        *ngIf="(filteredDepartmentsDataSource$ | async)?.length"
        class="flex"
        [tableClass]="'overview-table'"
        style="margin-top: 16px"
        [loggedInUser]='currentUser'
        [data]="filteredDepartmentsDataSource$ | async"
        [displayedColumns]="scopeTabService.departmentColumns"
        [noPagination]="true"
        [expandable]="true"
        [showBreadcrumb]="true"
        [maxColumns]="9"
        (onExpand)="onSelect($event)" >
        <ng-template let-element>
          <ng-container [ngTemplateOutlet]="roleSubTable" [ngTemplateOutletContext]="{$implicit: element}"></ng-container>
        </ng-template>
      </scope-ui-table>
      <scope-ui-table
        *ngIf="(filteredRolesDataSource$ | async)?.length"
        class="flex"
        [tableClass]="'overview-table'"
        style="margin-top: 16px"
        [loggedInUser]='currentUser'
        [data]="filteredRolesDataSource$ | async"
        [displayedColumns]="scopeTabService.roleColumns"
        [noPagination]="true"
        [expandable]="false"
        [showBreadcrumb]="true"
        [maxColumns]="9" />
      <scope-ui-table
        *ngIf="(filteredFeesDataSource$ | async)?.length"
        class="flex"
        [tableClass]="'overview-table'"
        style="margin-top: 16px"
        [loggedInUser]='currentUser'
        [data]="filteredFeesDataSource$ | async"
        [displayedColumns]="scopeTabService.feeColumns"
        [addToggleListColumn]="true"
        [noPagination]="true"
        [expandable]="false"
        [showBreadcrumb]="true"
        [showToggleMenu]="feeMenuOptions.length > 0"
        [menuOptions]="feeMenuOptions"
        [maxColumns]="9"
        [noPreferences]="true" />
      <scope-ui-table
        *ngIf="(filteredTPCsDataSource$ | async)?.length"
        class="flex"
        [tableClass]="'overview-table tpc-table'"
        style="margin-top: 16px"
        [loggedInUser]='currentUser'
        [data]="filteredTPCsDataSource$ | async"
        [displayedColumns]="scopeTabService.thirdPartyCostColumns"
        [noPagination]="true"
        [expandable]="false"
        [showBreadcrumb]="true"
        [maxColumns]="9" />
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
