import { Company } from '@core/model/company.model'
import { Type } from 'class-transformer'
import { User } from '@core/model/user.model'
import { Money } from '@app/features/scope-overview/model/money.model'
import { ScopeSection } from '@app/features/scope-overview/model/scope-section'
import { ScopeComponent } from '@app/features/scoping/models/component.model'
import { FeeItemInstance } from "@app/features/scope-overview/model/fee-item.model";
import { ThirdPartyCost } from "@app/features/scoping/models/third-party-cost.model";
import { DeliverableSection } from "@app/features/scope-overview/model/deliverable-section";
import { UserComment } from '@core/model/comment.model'
import { DeliverableType } from '@app/features/scope-overview/model/deliverable-type'
import { DeliverableReviewer } from '@app/features/scope-overview/model/deliverable-reviewer.interface';

export enum DeliveryStatus {
  COMPLETE = 'COMPLETE',
  ON_HOLD = 'ON_HOLD',
  IN_PROGRESS = 'IN_PROGRESS',
  TRADED = 'TRADED',
  TRADING_FROM_IN_PROGRESS = 'TRADING_FROM_IN_PROGRESS',
  TRADING_TO_IN_PROGRESS = 'TRADING_TO_IN_PROGRESS',
}

export interface SelectedDeliverable {
  name?: string
  deliverableType?: DeliverableType
  budget?: Money
  startDate?: Date
  endDate?: Date
  description?: string
  trafficSystemEntityMetadata?: any
}

export interface TradedDeliverable {
  sourceType: string;
  id: number;
  rateCardUnitMinutes: number;
  rateCardTotalMinutes: number;
  totalCostPrice: Money;
  unitCostPrice: Money;
  markup: number;
  totalProfit: Money;
  unitProfit: Money;
}

export class Deliverable {
  budgetBalance!: Money
  budget?: Money
  thirdPartyCostBudget?: Money
  cachedScopeMarkTotalHours!: number
  cachedScopeMarkTotalSellingPrice: any
  @Type(() => UserComment) comments!: UserComment[]
  @Type(() => Company)
  company!: Company
  componentsInitialised ?: boolean = false
  containsUnmappedScopeMarkRoles!: boolean
  @Type(() => User)
  createdBy!: User
  newFixedCostValue!: Money
  @Type(() => Date) createdTs!: Date
  @Type(() => ScopeSection) section!: ScopeSection
  @Type(() => ScopeComponent) components!: ScopeComponent[]
  deliveryStatus!: DeliveryStatus
  tradedFrom?: any
  currencyUnit!: string
  current!: boolean
  deleted!: boolean
  deliverableStatusTrading!: boolean
  deliverableTimeSheetEntries!: any[]
  description!: string
  discipline!: any
  @Type(() => Date) endDate!: Date
  @Type(() => FeeItemInstance) feeItems: FeeItemInstance[] = []
  fixedPricing!: boolean
  id!: number
  identificationType!: string
  language!: string
  librarySourced!: boolean
  markup!: number
  name!: string
  naturalSellingPrice!: any
  order!: number
  @Type(() => User)
  owner!: User
  privilegeCategories!: string[]
  quantity!: number
  rateCardTotalHours!: number
  rateCardTotalMinutes!: number
  rateCardUnitMinutes!: number
  readyToTraffic!: boolean
  reviewers!: DeliverableReviewer[]
  scope!: any
  scopeMarkTotalHours!: number
  @Type(() => Money) scopeMarkTotalSellingPrice!: Money
  scopeSourced!: boolean
  @Type(() => DeliverableSection) sections!: DeliverableSection[]
  source!: any
  sourceType!: string
  @Type(() => Date) startDate!: Date
  @Type(() => ThirdPartyCost) thirdPartyCosts: ThirdPartyCost[] = []
  @Type(() => Money) totalCostPrice!: Money
  @Type(() => Money) totalProfit!: Money
  @Type(() => Money) totalSellingPrice!: Money
  @Type(() => Money) unitSellingPrice!: Money
  totalRateCardHours!: string
  traded!: boolean
  tradedTo!: boolean
  trafficSystemEntityMetadata!: any
  type!: any
  unitCostPrice!: any
  unitProfit!: any
  @Type(() => User)
  updatedBy!: User
  @Type(() => Date) updatedTs!: Date
  @Type(() => Money) fixedCostOffset!: Money
  userAdditionalPrivileges!: any[]
  userPrivilegeRestrictions!: any[]
  tradesCount: number
  traffickedActualMinutes: number
  traffickedPercentageComplete: number
  feeValueTotal!: number
  @Type(() => Money) tpcValueTotal!: Money

  getTotalProfit() {
    if (!this.componentsInitialised) {
      return this.totalProfit
    }
    return this.getUnitProfit().multiply(this.quantity)
  }

  getTotalSellingPrice?(ctx: any = {}) {
    if (!this.componentsInitialised) {
      return this.totalSellingPrice
    }
    if (this.getUnitSellingPrice(ctx)) {
      if (ctx) {
        ctx.applyQuantity = true
      } else {
        ctx = { excludeFixedCost: false, excludeThirdPartyCosts: false, excludeFees: false, applyQuantity: true }
      }
      return this.getUnitSellingPrice(ctx)
    }
    return new Money(null, this.currencyUnit)
  }

  getUnitNaturalSellingPrice?() {
    return this.getUnitSellingPrice({ excludeFixedCost: true })
  }

  getUnitSellingPrice(ctx: any = { excludeFixedCost: false, excludeThirdPartyCosts: false, excludeFees: false, applyQuantity: false }) {
    if (!this.componentsInitialised) {
      return this.unitSellingPrice
    }

    let accu = () => {
      let total = new Money(0, this.currencyUnit)

      if (this.sections) {
        for (let section of this.sections) {
          var totalUserSellingPrice = section.getTotalSellingPrice(ctx, this)
          if (totalUserSellingPrice == null) return null
          total = total.add(totalUserSellingPrice)
        }
      }
      let defaultSectionComponents = this.defaultSectionComponents
      if (defaultSectionComponents) {
        for (let component of defaultSectionComponents) {
          let totalUserSellingPrice = component.getTotalSellingPrice(ctx)
          if (totalUserSellingPrice == null) return null
          total = total.add(totalUserSellingPrice)
        }
      }

      if (!ctx.excludeThirdPartyCosts) {
        this.thirdPartyCosts?.forEach(function (tpc: any) {
          total = total.add(tpc.calculateSellingPrice());
        });
      }

      return total
    }

    let total = accu()
    if (total) {
      if (this.fixedCostOffset != null && !ctx.excludeFixedCost) {
        total = total.add(this.fixedCostOffset)
      }
      if (total && ctx.applyQuantity) {
        total = total.multiply(this.quantity)
      }
      if (total && !ctx.excludeFees && this.fixedCostOffset == null) {
        total = total.addValue(this.getTotalFees(total.amount))
      }
    }

    return total
  }

  getTotalFees(amount: number | null = null) {
    let total = 0
    if (this.feeItems.length !== 0) {
      if (amount == null && this.feeItems.find((fee) => fee.feeItem.amountType !== 'MONETARY')) {
        amount = (this.getTotalSellingPrice({ excludeFees: true })?.amount || 0) / this.quantity
      }
      for (let i = 0; i < this.feeItems.length; i++) {
        total += Number(this.getFeePrice(this.feeItems[i], amount))
      }
    }
    return parseFloat(total.toFixed(2))
  }

  getFeePrice(fee: FeeItemInstance, amount: number): number {
    if (fee.feeItem.amountType === 'MONETARY') {
      return parseFloat(fee.feeItem.amount.toFixed(2))
    } else {
      return parseFloat(((Number(amount) * fee.feeItem.amount) / 100).toFixed(2))
    }
  }

  getUnitCostPrice() {
    if (!this.componentsInitialised){
      return this.unitCostPrice;
    }
    let total = new Money(0, this.currencyUnit)
    for (let i = 0; i < this.components.length; i++) {
      let component = this.components[i]
      let totalCost = component.getTotalCostPrice()
      if (totalCost == null) return null
      total = total.add(totalCost)
    }
    this.thirdPartyCosts?.forEach(function (tpc: any) {
      total = total.add(tpc.calculateCostPrice());
    });
    return total
  }

  getTotalCostPrice?() {
    if (!this.componentsInitialised) {
      return this.totalCostPrice
    }
    const costPrice = this.getUnitCostPrice()
    if (costPrice != null) {
      return costPrice.multiply(this.quantity)
    }
    return Money.of(0, this.currencyUnit)
  }

  getUnitProfit?() {
    if (!this.componentsInitialised) {
      return this.unitProfit
    }

    let totalUserSellingPrice = this.getUnitSellingPrice({ excludeFixedCost: false })
    if (totalUserSellingPrice != null) {
      const costPrice = this.getUnitCostPrice()
      if (costPrice != null) {
        return totalUserSellingPrice.subtractValue(costPrice.amount)
      }
    }
    return Money.of(0, this.currencyUnit)
  }

  getMarkup?() {
    if (!this.componentsInitialised) {
      return this.markup
    }
    var sellingPrice = this.getTotalSellingPrice()
    var totalRateCardCost = this.getTotalCostPrice()
    if (totalRateCardCost == null) {
      return null
    }
    var cost = totalRateCardCost
    if (cost.amount == 0) {
      return 100;
    }
    if (sellingPrice && (cost.amount || 0) > 0) {
      var grossProfit = sellingPrice.amount - (cost.amount || 0)
      var markup = (grossProfit / (cost.amount || 0)) * 100
      return parseFloat(markup.toFixed(2))
    }
    return 0
  }

  getMargin() {
    if (this.getUnitProfit().amount) {
      return (this.getUnitProfit().amount / this.getUnitSellingPrice().amount * 100).toFixed(2)
    }
    return 0
  }

  getTotalRateCardHours?(includeQuantity: boolean  = true) {
    if (!this.componentsInitialised) {
      return (this.rateCardUnitMinutes / 60) * (includeQuantity ? this.quantity : 1)
    }

    let total = 0
    for (var i = 0; i < this.components.length; i++) {
      const hours = this.components[i].getTotalRateCardHours()
      if (hours != null) {
        total += hours
      }
    }
    return total * (includeQuantity ? this.quantity : 1)
  }

  getBreadcrumb?() {
    return this.section?.name
  }

  getAllComponents?() {
    let components: ScopeComponent[] = []
    this.sections?.forEach((section) => {
      if (section.components?.length > 0) {
        components = components.concat(section.components)
      }
    })
    if (this.components?.length > 0) {
      components = components.concat(this.components)
    }
    return components
  }

  isTraded?() {
    return this.deliveryStatus === DeliveryStatus.TRADED
  }

  isBeingTraded?() {
    return this.deliveryStatus === DeliveryStatus.TRADING_FROM_IN_PROGRESS
  }

  isPendingTrade?() {
    return this.deliveryStatus === DeliveryStatus.TRADING_TO_IN_PROGRESS
  }

  isTradeProcessing() {
    return this.isPendingTrade() || this.isBeingTraded()
  }

  totalScopeMarkSellingPrice(companyCurrency?: string) {
    var currency = companyCurrency;
    if (currency == null) {
      currency = this.createdBy?.company?.currencyUnit || null;
    }

    var accu = () => {
      var total = new Money(null, currency);
      for (var i = 0; i < this.sections?.length; i++) {
        var section = this.sections[i];
        var totalScopeMarkSellingPrice = section.totalScopeMarkSellingPrice(this);
        if (totalScopeMarkSellingPrice == null || isNaN(totalScopeMarkSellingPrice.getValue())) return null;
        total = total.add(totalScopeMarkSellingPrice);
      }

      let defaultSectionComponents = this.defaultSectionComponents
      for (var i = 0; i < defaultSectionComponents?.length; i++) {
        var component = defaultSectionComponents[i];
        if (component.totalScopeMarkSellingPrice() == null)
          return null;
        total = total.add(component.totalScopeMarkSellingPrice());
      }
      return total;
    };

    return accu()
  }

  get defaultSectionComponents() {
    return this.components?.filter((c) => !c.section)
  }

  getFeeValueTotal() {
    if (!this.componentsInitialised) {
      return this.feeValueTotal
    }
    let total = this.getTotalFees()
    this.components.forEach((component) => {
      let componentTotal = component.getTotalFees()
      total += componentTotal || 0
    })
    return total
  }

  getTpcValueTotal() {
    if (!this.componentsInitialised) {
      return this.tpcValueTotal
    }
    let total = new Money(0, this.currencyUnit)
    this.thirdPartyCosts?.forEach(function (tpc: any) {
      total = total.add(tpc.calculateCostPrice());
    });
    this.sections.forEach((section) => {
      let sectionTotal = section.getTpcValueTotal()
      total = total.add(sectionTotal);
    })
    this.components.forEach((component) => {
      let componentTotal = component.thirdPartyCostTotal()
      total = total.add(componentTotal);
    })
    return total
  }

  getFtePercentage(fteHours, includeQuantity = true) {
    if (this.getTotalRateCardHours(includeQuantity) === 0) {
      return 0;
    }
    return parseFloat((this.getTotalRateCardHours(includeQuantity) / fteHours * 100).toFixed(2));
  }

  getCommentsCount() {
    let ownCommentsOwn = this.comments.filter((c) => c.sourceAttribute).length;
    let componentsCommentsCount = 0;
    let comps = this.getAllComponents();
    for (let i = 0; i < comps.length; i++) {
      componentsCommentsCount += comps[i].getCommentsCount();
    }
    let tpcCommentsCount = 0;
    let thirdPartyCosts = this.thirdPartyCosts;
    for (let j = 0; j < thirdPartyCosts.length; j++) {
      tpcCommentsCount += thirdPartyCosts[j].getCommentsCount();
    }
    return ownCommentsOwn + componentsCommentsCount + tpcCommentsCount;
  }

  getActualHours(): number {
    if (this.deliveryStatus !== 'TRADED' && this.deliveryStatus !== 'TRADING_FROM_IN_PROGRESS') {
      return this.traffickedActualMinutes / 60 || 0
    }
    return 0
  };
}
