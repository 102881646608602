import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnInit,
  Output, TemplateRef,
  ViewChild,
} from '@angular/core';
import { SharedModule } from '@app/shared/shared.module';
import { PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { ToggleListComponent } from '../toggle-list/toggle-list.component';
import { Preference } from '@app/core/model/user-preferences.interface';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { SvgIconComponent } from '../../svg-icon/svg-icon.component';
import { Router } from '@angular/router';
import { User } from '@app/core/model/user.model';
import { formatMonetaryValue, trackById, untilDestroyed } from '@app/shared/utils/utils';
import { LoginService } from '@app/features/user-account/services/login.service';
import { ScopeUiOptionsMenuComponent } from '../../scope-ui-options-menu/scope-ui-options-menu.component';
import { MenuOptions } from '@app/core/model/definitions/menu-options.interface';
import { LangfPipe } from '@app/shared/pipe/langf.pipe';
import { MappedEntity } from '@app/features/scoping/models/mapped-entity.model';
import { FolderVersion } from '@core/model/folder-version.model';
import { LanguageService } from '@core/service/language.service';
import { ScopeTeamMember } from '@core/model/scope-team.model';
import { ScopeUiDropdownComponent } from '@shared/components/ui-components/scope-ui-dropdown/scope-ui-dropdown.component';
import { MatTableDataSource } from '@angular/material/table';
import { ScopeOverviewSelectors } from '@app/features/scope-overview/store/selectors/scope-overview.selector';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { DataMappingService } from '@app/features/scoping/service/data-mapping.service'
import { instanceToInstance, plainToInstance } from 'class-transformer';
import { FeeItemInstance } from '@app/features/scope-overview/model/fee-item.model'
import { ScopeUiInputComponent } from '@shared/components/ui-components/scope-ui-input/scope-ui-input.component'
import {
  ScopeUiPaginatorComponent
} from '@shared/components/ui-components/scope-ui-paginator/scope-ui-paginator.component'
import { Money } from '@app/features/scope-overview/model/money.model';
import {
  ScopeUiAutocompleteComponent
} from '@shared/components/ui-components/scope-ui-autocomplete/scope-ui-autocomplete.component'
import {
  LinkToSelectorComponent
} from '@app/features/scope-overview/components/scope-tab/link-to-selector/link-to-selector.component';
import { ScopeUiCounterComponent } from '@shared/components/ui-components/scope-ui-counter/scope-ui-counter.component';
import {
  ScopeUiBoxInputComponent
} from '@shared/components/ui-components/scope-ui-box-input/scope-ui-box-input.component';
import { ThirdPartyCost } from '@app/features/scoping/models/third-party-cost.model';
import dayjs from 'dayjs';

@Component({
  selector: 'scope-ui-table',
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
    ToggleListComponent,
    SvgIconComponent,
    ScopeUiOptionsMenuComponent,
    ScopeUiDropdownComponent,
    ScopeUiInputComponent,
    ScopeUiPaginatorComponent,
    ScopeUiAutocompleteComponent,
    LinkToSelectorComponent,
    ScopeUiCounterComponent,
    ScopeUiBoxInputComponent,
  ],
  templateUrl: './scope-ui-table.component.html',
  animations: [
    trigger('detailExpand', [
      state('collapsed, void', style({ height: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ]),
  ],
  styleUrls: ['./scope-ui-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [LangfPipe],
})
export class ScopeUiTableComponent implements OnInit {
  private readonly destroy$;

  preferencesArray!: any[];

  @Input() expandedElements: { [id: number]: boolean } = {}
  @Output() expandedElementsChange = new EventEmitter<{ [id: number]: boolean }>()

  sortDirection: string | null;

  sortedColumn: string | null;

  customMoreOptionsMenuClasses: string[];

  _displayedColumns!: Preference[] | null;

  displayedColumnKeys!: string[] | null;

  dataSource!: MatTableDataSource<any>;

  isScopeLoading$: Observable<boolean>;

  @Input() loggedInUser: User

  _data: any

  @Input() set data(value: any) {
    this._data = value
    this.mapDataSource()
  }

  _unmappedData: any

  @Input() set unmappedData(value: any) {
    this._unmappedData = value
    this.mapDataSource()
  }

  @Input() addToggleListColumn

  @Input() set displayedColumns(value: Preference[] | null) {
    if (value) {
      this.preferencesArray = value.filter((p) =>
        (!p.requiredPrivilege || this.loggedInUser?.hasPrivilege(p.requiredPrivilege)) &&
        (!p.requiredSetting || this.loggedInUser?.company.hasApplicationSetting(p.requiredSetting)));
      this._displayedColumns = this.preferencesArray.filter((p) => p.selected && p.key !== 'SCOPE_NUMBER')
      this.displayedColumnKeys = this._displayedColumns.map((it) => it.key);
      this.addToggleColumn();
    } else {
      this._displayedColumns = null;
      this.displayedColumnKeys = null;
    }
  }

  @Input() noSort: boolean | undefined;

  @Input() noPagination: boolean | undefined;

  @Input() noPreferences: boolean | undefined;

  @Input() editAction: any | undefined;

  @Input() deleteAction: any | undefined;

  @Input() showToggleMenu: boolean = true;

  @Input() showAddButton: boolean = false;

  @Input() page: number | undefined;

  @Input() totalCount!: number | undefined;

  @Input() expandable!: boolean;

  @Input() showFooterRow!: boolean;

  @Input() parentEntity: any;

  @Input() headerExpandable!: boolean;

  @Input() showExpandAll!: boolean;

  @Input() showBreadcrumb!: boolean;

  @Input() showDescription: boolean = false;

  @Input() showHideDescriptionOnly: boolean = false;

  @Input() displayAllDescriptions: boolean = false;

  @Input() rowGaps: boolean = false;

  @Input() hideHeader: boolean = false;

  @Input() tableClass?: string;

  @Input() stickyHeader?: boolean;

  @Input() includeEmptyMessage?: boolean = true;

  @Input() editDeliverableAction: any;

  @Input() maxColumns: number = 9;

  _tableExpanded: boolean;
  @Input() set tableExpanded(value: boolean) {
    this._tableExpanded = value
  }

  @Input() currentScopeStatus?: string

  @Input() disableDragging!: boolean

  @Input() level: number

  @Input() set sort(value: Sort) {
    if (value) {
      this._sort = value
      this.setSortingDataAccessor()
      this.orderData()
    }
  }

  @Input() useSourceId: boolean = false
  @Input() hideToggles: boolean = false
  @Input() optimise: boolean = false

  @Output() disableDraggingChange = new EventEmitter<boolean>()

  @Output() tableExpandedChange = new EventEmitter<boolean>();

  @Output() pageChange: EventEmitter<PageEvent>;

  @Output() onSort: EventEmitter<Sort>;

  @Output() onToggle: EventEmitter<Preference>;

  @Output() onExpand: EventEmitter<any>;

  @Output() onSelect: EventEmitter<any>;

  @Output() addClicked: EventEmitter<void>;

  @Output() updateFeeItem: EventEmitter<FeeItemInstance>;

  @Output() updateScopeTpc: EventEmitter<ThirdPartyCost>;

  @Output() onSaveElement: EventEmitter<any>;

  @Output() linkElement: EventEmitter<any>;

  @Input() menuOptions?: MenuOptions[];

  loadingRowId: string | null = null;

  isOpen: boolean = false

  _sort: Sort

  @ViewChild(MatSort) set matSort(sort: MatSort) {
    if (this._data && this.noPagination) {
      this.orderData(sort)
    }
  }

  levels: { name: string, value: string }[]

  @ContentChild(TemplateRef) templateRef: TemplateRef<any>;

  constructor(private router: Router,
              private loginService: LoginService,
              private lang: LanguageService,
              private store:Store,
              private mappingService: DataMappingService,
              private cdr: ChangeDetectorRef) {
    this.destroy$ = untilDestroyed();
    this.dataSource = new MatTableDataSource()
    this.page = 0;
    this.customMoreOptionsMenuClasses = ['toggle-button', 'float-right'];
    this.pageChange = new EventEmitter<PageEvent>();
    this.onSort = new EventEmitter<Sort>();
    this.onToggle = new EventEmitter<Preference>();
    this.onExpand = new EventEmitter<any>();
    this.onSelect = new EventEmitter<any>();
    this.addClicked = new EventEmitter<void>();
    this.updateFeeItem = new EventEmitter<FeeItemInstance>();
    this.updateScopeTpc = new EventEmitter<ThirdPartyCost>();
    this.onSaveElement = new EventEmitter<any>();
    this.linkElement = new EventEmitter<any>();
    this.sortDirection = null;
    this.sortedColumn = null;
    this.isScopeLoading$ = this.store.select(ScopeOverviewSelectors.selectCurrentScopeLoading);
    this.levels = [
      { name: this.lang.get('scope'), value: 'scope' },
      { name: this.lang.get('stage'), value: 'section' },
      { name: this.lang.get('deliverable'), value: 'deliverable' },
      { name: this.lang.get('component'), value: 'component' }
    ]
  }

  ngOnInit(): void {
    this.addToggleColumn()
    this.mapDataSource()
  }

  addToggleColumn() {
    if (this.addToggleListColumn && !this.displayedColumnKeys.includes('toggleList')) {
      this._displayedColumns.push({ name: '', key: 'toggleList' })
      this.displayedColumnKeys.push('toggleList')
    }
  }

  mapDataSource = () => {
    if (this._unmappedData && this._displayedColumns) {
      this.dataSource = new MatTableDataSource(this.mappingService.transformArray<any>(this._unmappedData, this._displayedColumns))
      this.cdr.detectChanges()
      this.setSortingDataAccessor()
    } else if (this._data) {
      this.dataSource = new MatTableDataSource()
      if (!this.optimise) {
        this.dataSource.data = this._data
        this.cdr.detectChanges()
      }
      this.setSortingDataAccessor()
      this.orderData()
      if (this.optimise) { // Set data after sort for optimised rendering. Does not work in conjunction with async data
        this.dataSource.data = this._data
        setTimeout(() => this.cdr.detectChanges())
        this.optimise = false
      }
    }
  }

  setSortingDataAccessor() {
    if (!this.dataSource) return
    this.dataSource.sortData = (data: any[], sort: MatSort) => {
      const active = sort.active;
      const direction = sort.direction;
      if (!active || direction == '') {
        return data;
      }

      return data.sort((a, b) => {
        let valueA = (a[active] instanceof Money) ? a[active].amount : a[active];
        let valueB = (b[active] instanceof Money) ? b[active].amount : b[active];

        if (active === 'LINK_TO') {
          valueA = valueA?.name || 'Scope'
          valueB = valueB?.name || 'Scope'
        }

        if (active === 'NAME' && !valueA) {
          valueA = a.entity?.name
          valueB = b.entity?.name
        }

        const valueAType = typeof valueA;
        const valueBType = typeof valueB;

        if (valueAType === 'string' && valueBType === 'string') {
          return valueA.localeCompare(valueB) * (direction == 'asc' ? 1 : -1);
        }

        if (valueAType !== valueBType) {
          if (valueAType === 'number') {
            valueA += '';
          }
          if (valueBType === 'number') {
            valueB += '';
          }
        }

        let comparatorResult = 0;
        if (valueA != null && valueB != null) {
          if (valueA > valueB) {
            comparatorResult = 1;
          } else if (valueA < valueB) {
            comparatorResult = -1;
          }
        } else if (valueA != null) {
          comparatorResult = 1;
        } else if (valueB != null) {
          comparatorResult = -1;
        }

        return comparatorResult * (direction == 'asc' ? 1 : -1);
      });
    }
  }

  orderData(sort?: MatSort) {
    if (this.dataSource) {
      const matSort = sort || this.dataSource.sort || new MatSort();

      if (this._sort) matSort.sort({ id: this._sort.active, start: this._sort.direction, disableClear: false });

      this.dataSource.sort = matSort;
    }
  }

  shouldDisplayScopeNumber(): boolean {
    const scopeNumberPref = this.preferencesArray?.find((pref) => pref.key === 'SCOPE_NUMBER')
    return scopeNumberPref && scopeNumberPref.selected
  }

  getCellClass(column: string, value: any, entity: any): string {
    if (column === 'STATUS') {
      switch (value) {
        case 'CLOSED':
          return 'text-error-600 font-newJuneBold';
        case 'SUBMITTED':
        case 'IN_REVIEW':
          return 'text-critical-600 font-newJuneBold'
        case 'AGENCY_APPROVED':
          return 'text-success-700 font-newJuneBold';
        case 'CLIENT_APPROVED':
          return 'text-safe-700 font-newJuneBold';
        case 'SCOPING':
          return 'text-success-500 font-newJuneBold';
        case 'TRAFFICKED':
          return 'text-blue-500 font-newJuneBold';
        case 'DRAFT':
          return 'text-blue-violet-500 font-newJuneBold';
        case 'REVIEWED':
          return 'text-critical-700 font-newJuneBold';
        case 'REJECTED':
          return 'text-error-800 font-newJuneBold';
        default:
          return '';
      }
    } else if (column === 'BUDGET') {
      if (value !== '-') {
        return entity.hasEnoughBudget?.() ? 'text-success-800' : 'text-error-600';
      }
    }
    return this.level ? 'level-' + this.level : '';
  }

  transformDate(date: Date): string {
    let format = this.loggedInUser?.company?.companyDateFormat;
    if (!date) return '--';
    if (!format) {
      format = 'DD/MM/YYYY';
    }
    return dayjs.utc(date).format(format);
  }

  formatCellData(column: string, value: any, entity?: any): any {
    switch (column) {
      case 'DATE_CREATED':
      case 'DATE_APPROVED':
      case 'LAST_EDITED':
      case 'START_DATE':
      case 'END_DATE':
        if (!value || value === '-') {
          return '-';
        }
        return this.transformDate(new Date(value));
      case 'BUDGET':
      case 'BALANCE':
        if (entity instanceof FolderVersion) {
          if (value && value.length == 1) {
            return `${formatMonetaryValue(value[0].budget)}`;
          } else {
            return `${formatMonetaryValue(value.budget)}`
          }
        } else {
          return `${formatMonetaryValue(value)}`;
        }
      case 'VALUE': {
        if (entity instanceof FolderVersion) {
          if (value && value.length == 1) {
            return `${formatMonetaryValue(value[0])}`;
          } else {
            if (!Array.isArray(value)) {
              return `${formatMonetaryValue(value)}`
            }
            return ``
          }
        } else {
          return `${formatMonetaryValue(value)}`;
        }
      }
      case 'PROFIT':
      case 'AGENCY_PRICE':
      case 'SCOPEMARK_PRICE':
      case 'AGENCY_RATE':
      case 'AGENCY_COST':
      case 'WEIGHTED_RATE':
      case 'UNIT_COST':
      case 'OVERTIME_RATE':
      case 'OVERTIME_TOTAL':
      case 'SELLING_PRICE':
      case 'PRICE':
        return `${formatMonetaryValue(value)}`;
      case 'AMOUNT':
        return (value instanceof Money) ? `${formatMonetaryValue(value)}`: `${value}%`;
      case 'STATUS':
        return value.split('_').map(v => v.charAt(0).toUpperCase() + v.slice(1).toLowerCase()).join(' ')
      case 'MARK_UP':
        return value != null ? value + '%' : '-'
      case 'MARGIN':
        return value ? value.toFixed(2) + '%' : '-'
      case 'MANDATORY':
        return "Yes"
      default:
        return value;
    }
  }

  onChangePage(pageEvent: PageEvent): void {
    this.pageChange.emit(pageEvent);
  }

  onSortChange(event: Sort) {
    this.sortDirection = event.direction;
    this.sortedColumn = event.active;
    if (event.direction === '') {
      this.onSort.emit({ active: event.active, direction: 'desc' });
    } else {
      this.onSort.emit(event);
    }
  }

  onTogglePreference(preference: Preference) {
    this.onToggle.emit(preference);
  }

  onExpandElement(element: MappedEntity<any>, event?: any, value?: boolean) {
    event?.preventDefault();
    event?.stopPropagation();

    if (value === undefined) {
      value = !this.expandedElements[this.useSourceId ? element.entity.source.id : element.entity.id];
    }
    if (value) {
      this.expandedElements[this.useSourceId ? element.entity.source.id : element.entity.id] = value;
    } else {
      delete this.expandedElements[this.useSourceId ? element.entity.source.id : element.entity.id];
    }
    this.expandedElementsChange.emit(this.expandedElements)

    if (this.expandedElements[this.useSourceId ? element.entity.source.id : element.entity.id]) {
      this.onExpand.emit(element);
    }
  }

  expandTable() {
    if (!this.headerExpandable) {
      return;
    }
    this._tableExpanded = !this._tableExpanded;
    this.tableExpandedChange.emit(this._tableExpanded)
  }

  expandAll(event: any) {
    event.preventDefault();
    event.stopPropagation();
    if (!this.showExpandAll) {
      return;
    }
    let expand = !Object.keys(this.expandedElements).length
    this.dataSource.data.forEach((element) => this.onExpandElement(element, undefined, expand))
  }

  showToggle(element: MappedEntity<any>) {
    if (element.entity instanceof ScopeTeamMember || element.entity.identity?.identificationType === 'SCOPE_BY_ROLE') {
      return false;
    }
    return this.expandable && !this.hideToggles;
  }

  onSelectElement(element: MappedEntity<any>, event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.loadingRowId = element.entity.id; // Assuming 'id' is the unique identifier of the row
    this.onSelect.emit(element)
  }

  onAdd() {
    this.addClicked.emit();
  }

  isScopeOfWork(element: MappedEntity<any>) {
    return element.entity instanceof FolderVersion;
  }

  isFolderBudgetDisplay(element: MappedEntity<any>, key: string){
    return this.isScopeOfWork(element) && key === 'BUDGET' && element[key]?.length > 1
  }

  isFolderValueDisplay(element: MappedEntity<any>, key: string){
    return this.isScopeOfWork(element) && key === 'VALUE' && element[key]?.length > 1
  }

  saveElementDescription(description: string, entity: any) {
    let entityInstance = instanceToInstance(entity)
    entityInstance.description = description
    this.onSaveElement.emit(entityInstance);
  }

  saveElementInternalNote(internalNote: string, entity: any) {
    let entityInstance = instanceToInstance(entity)
    entityInstance.internalNote = internalNote
    this.onSaveElement.emit(entityInstance);
  }

  showFeeItemDescription?: MappedEntity<FeeItemInstance>
  showScopeTpcDescription?: MappedEntity<ThirdPartyCost>
  newFeeItemDescription?: string
  newScopeTpcDescription?: string

  toggleEditFeeItemDescription(element: MappedEntity<FeeItemInstance>) {
    if (this.showFeeItemDescription === element) {
      this.showFeeItemDescription = null
      this.newFeeItemDescription = null
    } else {
      this.showFeeItemDescription = element
      this.newFeeItemDescription = element.entity.feeItem.description
    }
  }

  toggleScopeTpcDescription(element: MappedEntity<ThirdPartyCost>) {
    if (this.showScopeTpcDescription === element) {
      this.showScopeTpcDescription = null
      this.newScopeTpcDescription = null
    } else {
      this.showScopeTpcDescription = element
      this.newScopeTpcDescription = element.entity.description.replace(/<\/?[^>]+(>|$)/g, "")
    }
  }

  setEditFeeItemDescription(element: MappedEntity<FeeItemInstance>) {
    this.updateFeeItem.emit({ ...element.entity, feeItem: { ...element.entity.feeItem, description: this.newFeeItemDescription } })
    this.toggleEditFeeItemDescription(element)
  }

  setScopeTpcItemDescription(element: MappedEntity<ThirdPartyCost>) {
    this.updateScopeTpc.emit(plainToInstance(ThirdPartyCost, { ...element.entity, description: this.newScopeTpcDescription }))
    this.toggleScopeTpcDescription(element)
  }

  onChangeLevel(feeItem: any, level: { name: string, value: string }) {
    feeItem.level = level
    if (level.value === 'scope') {
      this.linkElement.emit({feeItem})
    }
  }

  onLinkElement(feeItem: any, linkTo: any) {
    this.linkElement.emit({feeItem, linkTo})
  }

  protected readonly trackById = trackById;
  protected readonly Object = Object;
}
