import { ChangeDetectorRef, Component } from '@angular/core';
import { SafePipe } from "@app/shared/pipe/safe.pipe";
import { CompanyManagementService } from '@app/features/company-management/service/company-management.service';
import { ActivatedRoute, Router } from '@angular/router';
import { downloadFile, untilDestroyed } from '@shared/utils/utils';
import { SpreadsheetTemplate } from '@app/features/scope-overview/model/spreadsheet-template.model';
import { ScopeUiModalComponent } from '@shared/components/ui-components/scope-ui-modal/scope-ui-modal.component';
import { ModalConfig } from '@core/model/modal-config.model';
import { MatDialog } from '@angular/material/dialog';
import { UploadModalComponent } from '@shared/components/upload-modal/upload-modal.component';
import { SNACKBAR_LENGTH_SHORT, SnackbarEventType, SnackbarService } from '@shared/utils/snackbar.service';
import { UploadType } from '@app/features/company-management/models/upload-modal-config';

@Component({
  selector: 'spreadsheet-template',
  templateUrl: './spreadsheet-template.component.html',
  styleUrls: ['./spreadsheet-template.component.scss'],
  providers: [ SafePipe ]
})
export class SpreadsheetTemplateComponent {
  private readonly destroy$;
  id!: number;
  outputTemplate: SpreadsheetTemplate
  loading: boolean = true
  editName: boolean = false

  constructor(private manageService: CompanyManagementService,
              private route: ActivatedRoute,
              private cdr: ChangeDetectorRef,
              private router: Router,
              private dialog: MatDialog,
              private snackbarService: SnackbarService) {
    this.destroy$ = untilDestroyed();
    this.id = parseInt(route.snapshot.queryParamMap.get("id"))
    this.retrieveOutputTemplate()
  }

  retrieveOutputTemplate() {
    this.manageService.getXlsxTemplate(this.id).subscribe(template => {
      this.outputTemplate = template
      this.loading = false
      this.cdr.detectChanges()
    })
  }

  backToGallery() {
    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: { 'tab': 'output', 'type': 'spreadsheet' }
      }
    )
  }

  updateTemplate() {
    this.manageService.updateXlsxOutputTemplate(this.outputTemplate).subscribe({
      error: () => this.snackbarService.showDefaultErrorSnackbar()
    })
  }

  showDeleteXlsxTemplateModal() {
    let dialog = this.dialog.open(ScopeUiModalComponent, {
      data: new ModalConfig(
        `Delete Xlsx template`,
        `Are you sure you want to delete ${this.outputTemplate.name}`,
        'Delete',
        undefined,
        () => {
          dialog.close()
          this.manageService.deleteXlsxOutputTemplate(this.outputTemplate.id).subscribe({
            next: () => {
              this.backToGallery()
              this.snackbarService.showSnackbar(`Template ${this.outputTemplate.name} successfully deleted`, SNACKBAR_LENGTH_SHORT, SnackbarEventType.SUCCESS)
            },
            error: () => this.snackbarService.showDefaultErrorSnackbar()
          })
        }
      ),
    })
  }

  uploadExcelModal() {
    let dialog = this.dialog.open(UploadModalComponent, {
        data: {
          title: `Upload Excel Template`,
          body: `Upload Excel file`,
          type: UploadType.EXCEL_OUTPUT,
          submitFn: (file: File) => this.manageService.uploadExcelTemplate(file, this.id),
          successFn: (result: SpreadsheetTemplate) => {
            dialog.close()
            this.outputTemplate = result
            this.cdr.detectChanges()
          }
        }
      }
    )
  }

  downloadExcelTemplate() {
    if (this.outputTemplate.path) {
      this.manageService.downloadExcelTemplate(this.id).subscribe((res) => {
        downloadFile(res);
      });
    }
  }
}
