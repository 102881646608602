import { FormControl } from '@angular/forms'
import { Observable } from 'rxjs'

export class ModalConfig {
  title!: string
  body?: string
  confirmText?: string
  cancelText?: string
  confirmCallback?: Function
  cancelCallback?: Function
  inputs: GenericModalInput[]
  isMemberModal?: boolean
  limitBodyWidth?: boolean
  hasCancelButton?: boolean
  isFormValid?: Function
  flatButton?: boolean
  inlineHtmlBody?: boolean
  customContainerClass?: string

  constructor(
    title: string,
    body?: string,
    confirmText?: string,
    cancelText?: string,
    confirmCallback?: Function,
    cancelCallback?: Function,
    inputs?: GenericModalInput[],
    isMemberModal?: boolean,
    limitBodyWidth?: boolean,
    hasCancelButton?: boolean,
    isFormValid?: Function,
    flatButton?: boolean,
    inlineHtmlBody?: boolean,
    customContainerClass?: string,
  ) {
    this.title = title
    this.body = body
    this.confirmText = confirmText
    this.cancelText = cancelText
    this.confirmCallback = confirmCallback
    this.cancelCallback = cancelCallback
    this.inputs = inputs || []
    this.isMemberModal = isMemberModal || false
    this.limitBodyWidth = limitBodyWidth || false
    this.hasCancelButton = hasCancelButton
    this.isFormValid = isFormValid
    this.flatButton = flatButton
    this.inlineHtmlBody = inlineHtmlBody || false
    this.customContainerClass = customContainerClass
  }
}

export class GenericModalInput {
  name!: string
  type!: string
  control!: FormControl
  label?: string
  placeholder?: string
  options$?: Observable<any>
  options?: any
  displayField?: string
  multiselect?: boolean
  maxLength?: number
  optionFn?: (item: any) => any
  required?: boolean
  disabled?: boolean
}
