import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ImportSecondPartyModalComponent,
} from '@app/features/company-management/components/import-second-party-modal/import-second-party-modal.component';
import { UploadType } from '@app/features/company-management/models/upload-modal-config';
import { CompanyManagementService } from '@app/features/company-management/service/company-management.service';
import { OutputTemplate } from '@app/features/scope-overview/model/output-template.model';
import { RatecardIdentity } from '@app/features/scope-overview/model/ratecard-identity.model';
import { RatecardVersion } from '@app/features/scope-overview/model/ratecard-version.model';
import { ScopeTraffickingService } from '@app/features/scope-overview/service/scope-trafficking.service';
import { MyScopesService } from '@app/features/scoping/service/scoping.service';
import { UserService } from '@app/features/user-account/services/user.service';
import { CompanyType } from '@core/model/enums/company-type.enum';
import { UserPreferencesColumns } from '@core/model/enums/user-preferences-columns.enum';
import { ModalConfig } from '@core/model/modal-config.model';
import { OfficeLocation } from '@core/model/office-location';
import { PrivilegeRuleRestriction } from '@core/model/privilege-rule-restriction.model';
import { SecondParty } from '@core/model/second-party.model';
import { User } from '@core/model/user.model';
import { AuthService } from '@core/service/auth.service';
import { LanguageService } from '@core/service/language.service';
import { AuthActions } from '@core/store';
import { Store } from '@ngrx/store';
import { ScopeUiModalComponent } from '@shared/components/ui-components/scope-ui-modal/scope-ui-modal.component';
import { UploadModalComponent } from '@shared/components/upload-modal/upload-modal.component';
import {
  SNACKBAR_LENGTH_LONG,
  SNACKBAR_LENGTH_SHORT,
  SnackbarEventType,
  SnackbarService,
} from '@shared/utils/snackbar.service';
import { trackById } from '@shared/utils/utils';
import { instanceToInstance, plainToInstance } from 'class-transformer';
import { combineLatest, map } from 'rxjs';

@Component({
  selector: 'second-parties',
  templateUrl: './second-parties.component.html',
  styleUrls: ['./second-parties.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SecondPartiesComponent {
  loggedInUser!: User
  secondParties: SecondParty[] = []
  loadingSecondParties = true
  rateCards: RatecardVersion[]
  officeLocations: OfficeLocation[]
  outputTemplates: OutputTemplate[]
  menuOptions: any[]
  searchText = ''
  page = 0
  totalCount = 0
  pageSize = 25

  constructor(private authService: AuthService,
              private manageService: CompanyManagementService,
              private cdr: ChangeDetectorRef,
              private router: Router,
              public route: ActivatedRoute,
              private lang: LanguageService,
              private dialog: MatDialog,
              private snackbarService: SnackbarService,
              private scopingService: MyScopesService,
              private store: Store,
              private traffickingService: ScopeTraffickingService,
              private userService: UserService) {
    this.loggedInUser = this.authService.loggedInUser
    this.retrieveSecondPartiesAndRateCards()
    if (this.loggedInUser.company.companyType == CompanyType.CLIENT) {
      this.retrieveOfficeLocations()
    }
    this.retrieveOutputTemplates()

    this.menuOptions = [
      {
        name: 'Import',
        icon: 'import_contacts',
        click: () => this.openImportSecondPartyModal(),
        isVisible: () => {
          return this.hasValidThirdPartySystemSyncSettings() &&
            this.loggedInUser.hasPrivilege('COMPANY_TRAFFIC_SYSTEM__IMPORT')
        }
      },
      {
        name: 'Sync',
        icon: 'sync',
        click: () => this.syncImported(),
        isVisible: () => {
          return this.hasValidThirdPartySystemSyncSettings() &&
            this.loggedInUser.hasPrivilege('COMPANY_TRAFFIC_SYSTEM__IMPORT')
        }
      },
      {
        name: `Upload ${this.lang.get('second_party.p')}`,
        icon: 'import_export',
        click: () => this.upload(UploadType.CLIENTS),
        isVisible: () => {
          return this.loggedInUser.hasPrivilege('COMPANY_TRAFFIC_SYSTEM__IMPORT')
        }
      },
      {
        name: 'Upload Locations',
        icon: 'import_export',
        click: () => this.upload(UploadType.LOCATION),
        isVisible: () => {
          return this.loggedInUser.hasPrivilege('COMPANY_TRAFFIC_SYSTEM__IMPORT')
        }
      },
      {
        name: 'Upload Contacts',
        icon: 'import_export',
        click: () => this.upload(UploadType.CONTACT),
        isVisible: () => {
          return this.loggedInUser.hasPrivilege('COMPANY_TRAFFIC_SYSTEM__IMPORT')
        }
      },
      {
        name: `Upload ${this.lang.get('brand.p')}`,
        icon: 'import_export',
        click: () => this.upload(UploadType.BRAND),
        isVisible: () => {
          return this.loggedInUser.hasPrivilege('COMPANY_TRAFFIC_SYSTEM__IMPORT')
        }
      }
    ]
  }

  hasValidThirdPartySystemSyncSettings() {
    let company = this.loggedInUser.company;
    let ts = company.trafficSystemSettings ? company.trafficSystemSettings.trafficSystem : null;
    if (ts == null) {
      return false;
    }
    if (ts.sourceType == "SCOPE"){
      return true;
    }
    let config = ts.externalTrafficSystemConfig
    return !config || (config.secondParty && config.secondParty.url)
  }

  retrieveSecondPartiesAndRateCards() {
    this.userService.getUserPageSizePreferences(UserPreferencesColumns.SECONDPARTIES_PAGESIZE).subscribe({
      next: (pageSize) => {
        if (pageSize) {
          this.pageSize = pageSize
          this.cdr.detectChanges()
        }

        combineLatest([this.manageService.getOwnSecondPartiesAndGroupShared(this.page, this.pageSize, this.searchText), this.manageService.getAllRateCards()]).pipe(
          map(([secondParties, rateCards]) => {
            this.rateCards = rateCards
              .filter(r => !r.rateCardIdentity.archived && (!r.rateCardIdentity.scopeMarkCard ||
                !this.loggedInUser.company.hasApplicationSetting("RATECARD__SCOPEMARK__HIDE")))
              .sort((a, b) => a.name.localeCompare(b.name));
            secondParties.content.forEach(sp => {
              if (sp.rateCardIdentities.length === 0) {
                let rtIdentity = this.rateCards.find(rc => rc.rateCardIdentity.defaultCard)?.rateCardIdentity;
                if (rtIdentity) {
                  sp.rateCardIdentities.push(rtIdentity)
                }
              }
            })
            this.secondParties = secondParties.content
            this.loadingSecondParties = false
            this.totalCount = secondParties.totalElements
            this.cdr.detectChanges()
          })
        ).subscribe({
          error: _ => {
            this.loadingSecondParties = false
            this.cdr.detectChanges()
            this.snackbarService.showDefaultErrorSnackbar()
          }
        })
      }
    })
  }

  retrieveSecondParties() {
    this.loadingSecondParties = true
    this.manageService.getOwnSecondPartiesAndGroupShared(this.page, this.pageSize, this.searchText).subscribe({
      next: (secondParties) => {
        secondParties.content.forEach(sp => {
          if (sp.rateCardIdentities.length === 0){
            let rtIdentity = this.rateCards.find(rc => rc.rateCardIdentity.defaultCard)?.rateCardIdentity;
            if (rtIdentity) {
              sp.rateCardIdentities.push(rtIdentity)
            }
          }
        })
        this.secondParties = secondParties.content
        this.loadingSecondParties = false
        this.totalCount = secondParties.totalElements
        this.cdr.detectChanges()
      },
      error: _ => {
        this.loadingSecondParties = false
        this.cdr.detectChanges()
        this.snackbarService.showDefaultErrorSnackbar()
      }
    })
  }

  retrieveOfficeLocations() {
    this.scopingService.getOfficeLocations().subscribe({
      next: (result) => {
        this.officeLocations = result.sort((a, b) => a.name.localeCompare(b.name));
      }
    })
  }

  retrieveOutputTemplates() {
    this.manageService.getAllWordTemplates().subscribe({
      next: (result) => {
        this.outputTemplates = result.sort((a, b) => a.name.localeCompare(b.name));
      }
    })
  }

  selectSecondParty(id) {
    this.router.navigate(
      [`/manage/client/${id}`],
      {
        relativeTo: this.route
      }
    )
  }

  getRateCardIdentity = (option: RatecardVersion) => option.rateCardIdentity

  changeAssociatedSecondPartyRateCards(secondParty: SecondParty, rateCards: RatecardIdentity[]) {
    secondParty.rateCardIdentities = rateCards
    this.manageService.updateSecondPartyRateCards(secondParty).subscribe({
      next: () => {
        this.snackbarService.showSnackbar(`Successfully updated the Rate Cards of ${this.lang.get('second_party|l')} ${secondParty.name}.`, SNACKBAR_LENGTH_LONG, SnackbarEventType.SUCCESS)
      },
      error: () => {
        this.snackbarService.showDefaultErrorSnackbar()
      }
    })
  }

  showDeleteSecondPartyModal(secondParty: SecondParty) {
    let dialog = this.dialog.open(ScopeUiModalComponent, {
      data: new ModalConfig(
        `Delete ${this.lang.get('second_party')}`,
        `Are you sure you want to delete the ${this.lang.get('second_party|l')} ${secondParty.name}? If you click Delete now all existing scopes created for this ${this.lang.get('second_party|l')} will be archived`,
        'Delete',
        undefined,
        () => {
          dialog.close()
          this.manageService.deleteSecondParty(secondParty.id).subscribe({
            next: () => {
              this.retrieveSecondParties()
              this.snackbarService.showSnackbar(`${secondParty.name} successfully deleted`, SNACKBAR_LENGTH_SHORT, SnackbarEventType.SUCCESS)
            },
            error: () => this.snackbarService.showDefaultErrorSnackbar()
          })
        },
        undefined,
        undefined,
        undefined,
        true
      ),
    })
  }

  showAddSecondPartyDialog() {
    let inputs: any[] = [
      { name: 'name', control: new FormControl(''), type: 'text', required: true,
        label: `${this.lang.get('second_party')} name`, maxLength: 50 },
      { name: 'ratecards', control: new FormControl(null), type: 'select', label: `Rate Cards`, required: true,
        multiselect: true, options: this.rateCards,
        optionFn: (item: RatecardVersion) => `${item.name} (${item.currencyCode})` },
      { name: 'output', control: new FormControl(null), type: 'select', label: `${this.lang.get('doc_template')}`,
        required: false, multiselect: false, options: this.outputTemplates.filter(o => o.templateScopeType) },
      { name: 'folderOutput', control: new FormControl(null), type: 'select',
        label: `${this.lang.get('sow')} ${this.lang.get('doc_template')}`, required: false, multiselect: false,
        options: this.outputTemplates.filter(o => !o.templateScopeType)
      }
    ]

    if (this.loggedInUser.company.companyType == CompanyType.CLIENT) {
      inputs.splice(2, 0, { name: 'location', control: new FormControl(null), type: 'select', label: `Office Location`, required: true,
          multiselect: false, options: this.officeLocations })
    }

    let dialog = this.dialog.open(ScopeUiModalComponent, {
      data: new ModalConfig(
        `Add ${this.lang.get('second_party')}`,
        `Create a new ${this.lang.get('second_party|l')} here - make sure your ${this.lang.get('second_party|l')} name is not a duplicate and pick from a list of existing rate cards. Use the default rate card if you do not have rate set up for this ${this.lang.get('second_party|l')}.`,
        `Add ${this.lang.get('second_party')}`,
        undefined,
        (form: FormControl) => {
          let secondParty = {
            name: form.get('name').value,
            assignOfficeLocationId: form.get('location')?.value?.id,
            scopeOutputTemplate: form.get('output').value,
            sowOutputTemplate: form.get('folderOutput').value,
            rateCardIdentities: form.get('ratecards').value.map(r => r.rateCardIdentity)
          }
          this.manageService.addSecondParty(secondParty).subscribe({
            next: (result) => {
              dialog.close()
              if (this.loggedInUser.hasLimitedPermissions()) {
                this.addSecondParty(result)
              } else {
                this.snackbarService.showSnackbar(`${secondParty.name} was created. Adding it to your permission list...`, SNACKBAR_LENGTH_LONG, SnackbarEventType.SUCCESS)
                this.addSecondPartyToPrivilegeGroup(result)
              }
            },
            error: (error) => {
              if (error.status == 409){
                form.get('name').setErrors({conflict: true})
              } else {
                this.snackbarService.showDefaultErrorSnackbar()
              }
            }
          })
        },
        undefined,
        inputs,
        false,
        true,
        undefined,
        (form: FormControl) => !!form.get('name')?.value && form.get('ratecards').value?.length &&
          (this.loggedInUser.company.companyType !== CompanyType.CLIENT || form.get('location').value)
      ),
    })
  }

  addSecondParty(secondParty: SecondParty, showSnackbar: boolean = true) {
    this.retrieveSecondParties()
    if (showSnackbar) {
      this.snackbarService.showSnackbar(`${secondParty.name} was created`, SNACKBAR_LENGTH_SHORT, SnackbarEventType.SUCCESS)
    }
  }

  addSecondPartyToPrivilegeGroup(secondParty: SecondParty, showSnackbar: boolean = true) {
    let user = instanceToInstance(this.loggedInUser)
    let allClientAcceptPrivileges = user.privilegeGroup.privilegeRules
      .filter(p => p.privilegeMeta.restrictions && p.privilegeMeta.restrictions.bySecondParty && !p.disabled)
    let rule = user.privilegeGroup.privilegeRules
      .find(rule => rule.privilege === "SECOND_PARTY__VIEW");
    let ruleRestriction = plainToInstance(PrivilegeRuleRestriction, {secondParty: secondParty});
    rule.addRestriction(ruleRestriction);
    this.authService.addPrivilegeRules(user.id, user.privilegeGroup.id, {
      privilegeRules: allClientAcceptPrivileges,
      restrictions: [ruleRestriction]
    }).subscribe({
      next: (result) => {
        this.addSecondParty(secondParty, showSnackbar)
        this.store.dispatch(AuthActions.addPrivilegeRulesSuccess({ privilegeGroup: result }))
      },
      error: () => this.snackbarService.showDefaultErrorSnackbar()
    })
  }

  openImportSecondPartyModal(){
    this.traffickingService.assertActiveSystemConfigured(this.loggedInUser.company)

    let dialog = this.dialog.open(ImportSecondPartyModalComponent, {
        data: {
          trafficSystem: this.loggedInUser.company.trafficSystemSettings.trafficSystem,
          confirmCallback: (data: any[]) => {
            data.forEach((secondParty) => {
              if (this.loggedInUser.hasLimitedPermissions()) {
                this.addSecondParty(secondParty, false)
              } else {
                this.addSecondPartyToPrivilegeGroup(secondParty, false)
              }
            })
            dialog.close()
          },
          cancelCallback: () => dialog.close()
        }
      }
    )
  }

  syncImported() {
    this.traffickingService.assertActiveSystemConfigured(this.loggedInUser.company)

    let inputs: any[] = [
      { name: 'appSettings', control: new FormControl(''), type: 'toggle', required: true,
        label: `Force Sync ${this.lang.get('second_party')} names` }
    ]

    let dialog = this.dialog.open(ScopeUiModalComponent, {
      data: new ModalConfig(
        `Sync ${this.lang.get('second_party.p')}`,
        'Syncing Options',
        `Sync`,
        undefined,
        (form: FormControl) => {
          this.manageService.syncTrafficImportsSecondParty(form.get('appSettings').value).subscribe({
            next: () => {
              dialog.close()
              this.retrieveSecondPartiesAndRateCards()
            },
            error: (error) => {
              this.traffickingService.validateServerError(error, () => dialog.close(), () => this.renderDownloadFileSync())
            }
          })
        },
        undefined,
        inputs,
        false,
        true,
        undefined
      ),
    })
  }

  renderDownloadFileSync () {
    this.manageService.renderTrafficSystemErrorOnSync('secondparty').subscribe({
      next: (response: any) => {
        let blob = new Blob([JSON.stringify(response.data)], {type: 'text/plain'})
        let contentDispositionHeader = response.headers('Content-Disposition')
        let result = (contentDispositionHeader.split(';')[1].trim().split('=')[1]).replace(/"/g, '').trim()
        let name = result.length > 0 ? result : "fileData.txt"
        let downloadUrl = (window.URL || window.webkitURL).createObjectURL(blob)
        let a = $(`<a href='${downloadUrl}' download="${name}"></a>`)
        a[0].click()
      },
      error: (error) => {
        if (error.status == 404) {
          this.snackbarService.showSnackbar("The cached data has expired.", SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR);
        }
      }
    })
  }

  upload(type: UploadType) {
    let title
    switch (type) {
      case UploadType.CLIENTS:
        title = `Upload ${this.lang.get('second_party.p')}`;
        break;
      case UploadType.LOCATION:
        title = "Upload Locations";
        break;
      case UploadType.CONTACT:
        title = "Upload Contacts";
        break;
      case UploadType.BRAND:
        title = `Upload ${this.lang.get('brand.p')}`;
    }
    let dialog = this.dialog.open(UploadModalComponent, {
        data: {
          title: title,
          body: `${title} using Excel file`,
          type: type,
          submitFn: (file: File) => this.manageService.import(file, type),
          successFn: () => {
            dialog.close()
            this.snackbarService.showSnackbar(`File was successfully uploaded. Please, refresh the page.`, SNACKBAR_LENGTH_LONG, SnackbarEventType.SUCCESS)
          },
          showBody: true
        }
      }
    )
  }

  onChangePage(pageEvent: PageEvent) {
    const { pageIndex, pageSize } = pageEvent
    this.page = pageIndex
    if (this.pageSize !== pageSize) {
      this.userService.setUserPageSizePreferences(UserPreferencesColumns.SECONDPARTIES_PAGESIZE, pageSize).subscribe();
    }
    this.pageSize = pageSize
    this.retrieveSecondParties()
  }

  protected readonly trackById = trackById;
}
