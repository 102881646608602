import { Observable } from 'rxjs';
import { RatecardVersion } from '@app/features/scope-overview/model/ratecard-version.model';

export interface UploadModalConfig {
  title: string
  body?: string
  type: UploadType
  submitFn: (file: File) => Observable<any>
  successFn: (result: any) => void
  showRemoveOption?: boolean
  removeOptionText?: string
  removeFn?: () => void
  showBody?: boolean
  rateCards$?: Observable<RatecardVersion[]>
}

export enum UploadType {
  WORD_OUTPUT = "word_output",
  EXCEL_OUTPUT = "excel_output",
  CLIENTS = "clients",
  LOCATION = "location",
  CONTACT = "contact",
  BRAND = "brand",
  RATECARD = "ratecard",
  DELIVERABLE = "deliverable",
  COMPONENT = "component",
  TASK = "task"
}
